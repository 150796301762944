import { Injectable } from '@angular/core';

import { EditalEntity } from '@domain/entities/edital/edital.entity';
import { EditalModel } from './edital.model';
import { CategoriaMapper } from '../categoria/categoria.mapper';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class EditalMapper implements Mapper<EditalModel, EditalEntity> {
    
    constructor(
        private readonly _mapperCategoria: CategoriaMapper,
    ) { }

    mapFrom(param: EditalEntity): EditalModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            nome: param?.nome,
            objeto: param?.objeto,
            inicio_inscricao: param?.inicioInscricao,
            fim_inscricao: param?.fimInscricao,
            inicio_avaliacao: param?.inicioAvaliacao,
            fim_avaliacao: param?.fimAvaliacao,
            inicio_recurso: param?.inicioRecurso,
            fim_recurso: param?.fimRecurso,
            inicio_avaliacao_recurso: param?.inicioAvaliacaoRecurso,
            fim_avaliacao_recurso: param?.fimAvaliacaoRecurso,
            ativo: param?.ativo,

            deleted_at: param?.deletedAt,
            categoria: param?.categoria?.map(categoria => this._mapperCategoria.mapFrom(categoria)),
        };
    }

    mapTo(param: EditalModel): EditalEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            nome: param?.nome,
            objeto: param?.objeto,
            inicioInscricao: param?.inicio_inscricao,
            fimInscricao: param?.fim_inscricao,
            inicioAvaliacao: param?.inicio_avaliacao,
            fimAvaliacao: param?.fim_avaliacao,
            inicioRecurso: param?.inicio_recurso,
            fimRecurso: param?.fim_recurso,
            inicioAvaliacaoRecurso: param?.inicio_avaliacao_recurso,
            fimAvaliacaoRecurso: param?.fim_avaliacao_recurso,
            ativo: !!param?.ativo,

            deletedAt: param?.deleted_at,
            categoria: param?.categoria?.map(categoria => this._mapperCategoria.mapTo(categoria)),
        };
    }
}