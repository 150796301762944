import { Pipe, PipeTransform } from '@angular/core';

import { cnpjCpfFormat } from '@shared/utils/utils';

@Pipe({
    name: 'cpfCnpj'
})
export class CpfCnpjPipe implements PipeTransform {
    transform(cpfCnpj: string) {
        return cnpjCpfFormat(cpfCnpj)
    }
}