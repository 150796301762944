import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InscricaoRemoteDataSource } from './inscricao-remote.datasource';
import { InscricaoEntity } from '@domain/entities/inscricao/inscricao.entity';
import { InscricaoMapper } from '@data/models/inscricao/inscricao.mapper';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { SuccessVoid } from '@infra/success/success';
import { HttpUtils } from '@shared/utils/http-utils';

@Injectable()
export class InscricaoRemoteDataSourceImpl implements InscricaoRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: InscricaoMapper,
    ) { }

    deleteInscricaoByIdPublic(idPublic: string): Observable<SuccessVoid> {
        return this._http.delete<SuccessVoid>(`${Endpoints.inscricao}/${idPublic}`).pipe(
            map(() => new SuccessVoid())
        );
    }

    restoreInscricaoByIdPublic(idPublic: string): Observable<SuccessVoid> {
        return this._http.patch<SuccessVoid>(`${Endpoints.inscricao}/${idPublic}/restore`, {}).pipe(
            map(() => new SuccessVoid())
        );
    }

    getInscricaoUltimaEtapaByIdPublic(idPublic: string): Observable<InscricaoEntity> {
        return this._http.get<InscricaoEntity>(`${Endpoints.adminInscricao}/ultimo-status-detalhado/${idPublic}`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    updateInscricaoLogByIdPublic(param: InscricaoEntity, idPublic: string): Observable<SuccessVoid> {
        const dataSubmit = HttpUtils.jsonToFormData(this._mapper.mapFrom(param));

        return this._http.post<SuccessVoid>(`${Endpoints.inscricao}/update/${idPublic}`, dataSubmit).pipe(
            map(() => new SuccessVoid())
        );
    }
}