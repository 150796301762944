import { Observable } from 'rxjs';

import { UserEntity } from '@domain/entities/user/user.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';
import { SuccessVoid } from '@infra/success/success';

export abstract class UserRemoteDataSource {
    abstract getUserAuth(): Observable<UserEntity>;
    abstract getAllUserPaginated(params: PaginatorParam): Observable<PaginatorEntity<UserEntity[]>>;
    abstract updateStatusUserByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid>;
    abstract updateUserByIdPublic(param: UserEntity, idPublic: string): Observable<SuccessVoid>;
    abstract deleteUserByIdPublic(idPublic: string): Observable<SuccessVoid>;
    abstract restoreUserByIdPublic(idPublic: string): Observable<SuccessVoid>;
    abstract saveUser(param: UserEntity): Observable<SuccessVoid>;

    abstract getUserByIdPublic(idPublic: string): Observable<UserEntity>;
    abstract getUserProdutoraByIdPublic(idPublic: string): Observable<UserEntity>;
    abstract updateSelfUser(param: UserEntity): Observable<SuccessVoid>;
    abstract getMyUser(): Observable<UserEntity>;
}