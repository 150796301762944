import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthUserLocalDataSource } from './auth-user-local.datasource';
import { AuthUserRemoteDataSource } from './auth-user-remote.datasource';
import { SuccessVoid } from '@infra/success/success';
import { SessionAuthed } from '@shared/utils/session-authed';

@Injectable()
export class AuthUserLocalDataSourceImpl implements AuthUserLocalDataSource {

    constructor(
        private readonly _session: SessionAuthed,
        private readonly _authUserRemoteDataSource: AuthUserRemoteDataSource,
    ) { }

    isLogged(): boolean {
        return this._session.checkSession();
    }

    logout(): Observable<SuccessVoid> {
        return this._authUserRemoteDataSource.logout();
    }
}