import { Injectable } from '@angular/core';

import { CategoriaEntity } from '@domain/entities/categoria/categoria.entity';
import { CategoriaModel } from './categoria.model';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class CategoriaMapper implements Mapper<CategoriaModel, CategoriaEntity> {

    mapFrom(param: CategoriaEntity): CategoriaModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            nome: param?.nome?.trim()?.toUpperCase(),
        };
    }

    mapTo(param: CategoriaModel): CategoriaEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            nome: param?.nome?.trim()?.toUpperCase(),

            categoriaEditalId: param?.categoria_edital_id,
        };
    }
}