import { Injectable } from '@angular/core';

import { InscricaoCategoriaEntity } from '@domain/entities/inscricao-categoria/inscricao-categoria.entity';
import { InscricaoCategoriaModel } from './inscricao-categoria.model';
import { CategoriaEditalMapper } from '../categoria-edital/categoria-edital.mapper';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class InscricaoCategoriaMapper implements Mapper<InscricaoCategoriaModel, InscricaoCategoriaEntity> {
    
    constructor(
        private readonly _mapperCategoriaEdital: CategoriaEditalMapper,
    ) { }

    mapFrom(param: InscricaoCategoriaEntity): InscricaoCategoriaModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            ativo: !!param?.ativo,
            atracao: param?.atracao?.trim()?.toUpperCase(),
            categoria_edital: this._mapperCategoriaEdital.mapFrom(param?.categoriaEdital),
        };
    }

    mapTo(param: InscricaoCategoriaModel): InscricaoCategoriaEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            ativo: !!param?.ativo,
            atracao: param?.atracao?.trim()?.toUpperCase(),
            categoriaEdital: this._mapperCategoriaEdital.mapTo(param?.categoria_edital),
        };
    }
}