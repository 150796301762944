import { Injectable } from '@angular/core';

import { StatusEntity } from '@domain/entities/status/status.entity';
import { StatusModel } from './status.model';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class StatusMapper implements Mapper<StatusModel, StatusEntity> {

    mapFrom(param: StatusEntity): StatusModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            descricao: param?.descricao,
        };
    }

    mapTo(param: StatusModel): StatusEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            descricao: param?.descricao,
        };
    }
}