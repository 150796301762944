import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { BancoEntity } from "@domain/entities/banco/banco.entity";
import { BancoRepository } from "@domain/repositories/banco/banco.repository";
import { BancoRemoteDataSource } from "@data/data-source/banco/banco-remote.datasource";
import { Failure } from '@infra/contracts/failure.contract';

@Injectable()
export class BancoRepositoryImpl implements BancoRepository {

    constructor(
        private readonly _remoteDataSource: BancoRemoteDataSource,
    ) { }

    getAllBanco(): Observable<Either<Failure, BancoEntity[]>> {
        return this._remoteDataSource.getAllBanco().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }
}