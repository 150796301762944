import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthUserLocalDataSource } from '@data/data-source/auth/auth-user-local.datasource';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private readonly _authUserLocalDataSource: AuthUserLocalDataSource,
        private router: Router
    ) { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if(this._authUserLocalDataSource.isLogged()) {
            return true;
        }

        this.router.navigateByUrl('/login', { replaceUrl: true });
        return false;
    }
}