import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { InscricaoRepository } from '@domain/repositories/inscricao/inscricao.repository';
import { InscricaoRemoteDataSource } from '@data/data-source/inscricao/inscricao-remote.datasource';
import { InscricaoEntity } from "@domain/entities/inscricao/inscricao.entity";
import { SuccessVoid } from "@infra/success/success";
import { Failure } from '@infra/contracts/failure.contract';

@Injectable()
export class InscricaoRepositoryImpl implements InscricaoRepository {

    constructor(
        private readonly _remoteDataSource: InscricaoRemoteDataSource,
    ) { }

    deleteInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.deleteInscricaoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    restoreInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.restoreInscricaoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    getInscricaoUltimaEtapaByIdPublic(idPublic: string): Observable<Either<Failure, InscricaoEntity>> {
        return this._remoteDataSource.getInscricaoUltimaEtapaByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    updateInscricaoLogByIdPublic(param: InscricaoEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateInscricaoLogByIdPublic(param, idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }
}