import { Injectable } from '@angular/core';

import { InscricaoModel } from './inscricao.model';
import { UserMapper } from '../user/user.mapper';
import { InscricaoCategoriaMapper } from '../inscricao-categoria/inscricao-categoria.mapper';
import { EditalMapper } from '../edital/edital.mapper';
import { StatusMapper } from '../status/status.mapper';
import { EtapaMapper } from '../etapa/etapa.mapper';
import { BancoMapper } from '../banco/banco.mapper';
import { LogArquivoInscricaoMapper } from '../log-arquivo-inscricao/log-arquivo-inscricao.mapper';
import { ArquivoUsuarioMapper } from '../arquivo-usuario/arquivo-usuario.mapper';
import { InscricaoEntity } from '@domain/entities/inscricao/inscricao.entity';
import { Mapper } from "@data/contracts/mapper.contract";
import { CategoriaEditalMapper } from '../categoria-edital/categoria-edital.mapper';

@Injectable()
export class InscricaoMapper implements Mapper<InscricaoModel, InscricaoEntity> {
    
    constructor(
        private readonly _mapperUser: UserMapper,
        private readonly _mapperInscricaoCategoria: InscricaoCategoriaMapper,
        private readonly _mapperEdital: EditalMapper,
        private readonly _mapperStatus: StatusMapper,
        private readonly _mapperEtapa: EtapaMapper,
        private readonly _mapperBanco: BancoMapper,
        private readonly _mapperLogArquivoInscricao: LogArquivoInscricaoMapper,
        private readonly _mapperArquivoUsuarioMapper: ArquivoUsuarioMapper,
        private readonly _mapperCategoriaEditalMapper: CategoriaEditalMapper,
    ) { }

    mapFrom(param: InscricaoEntity): InscricaoModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            protocolo: param?.protocolo,
            edital: this._mapperEdital.mapFrom(param?.edital),
            categoria_inscricao: param?.categoriaInscricao?.map(categoria => this._mapperInscricaoCategoria.mapFrom(categoria)),
            log_user: this._mapperUser.mapFrom(param?.logUser),
            status: this._mapperStatus.mapFrom(param?.status),
            etapa: this._mapperEtapa.mapFrom(param?.etapa),
            avaliador: this._mapperUser.mapFrom(param?.avaliador),
            avaliador_recurso: this._mapperUser.mapFrom(param?.avaliadorRecurso),
            produtora: this._mapperUser.mapFrom(param?.produtora),
            
            banco: this._mapperBanco.mapFrom(param?.banco),
            conta: param?.conta?.toString()?.replace(/\D/g, ''),
            conta_dv: param?.contaDv,
            agencia: param?.agencia?.toString()?.replace(/\D/g, ''),
            agencia_dv: param?.agenciaDv,

            log_arquivo: param?.logArquivo?.map(arquivo => this._mapperLogArquivoInscricao.mapFrom(arquivo)),
            
            user: this._mapperUser.mapFrom(param?.user),
            categoria_edital: param?.categoriaEdital?.map(categoria => this._mapperCategoriaEditalMapper.mapFrom(categoria)),
            arquivo: param?.arquivo?.map(arquivo => this._mapperArquivoUsuarioMapper.mapFrom(arquivo)),
        };
    }

    mapTo(param: InscricaoModel): InscricaoEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            protocolo: param?.protocolo,
            edital: this._mapperEdital.mapTo(param?.edital),
            categoriaInscricao: param?.categoria_inscricao?.map(categoria => this._mapperInscricaoCategoria.mapTo(categoria)),
            logUser: this._mapperUser.mapTo(param?.log_user),
            status: this._mapperStatus.mapTo(param?.status),
            etapa: this._mapperEtapa.mapTo(param?.etapa),
            avaliador: this._mapperUser.mapTo(param?.avaliador),
            avaliadorRecurso: this._mapperUser.mapTo(param?.avaliador_recurso),
            produtora: this._mapperUser.mapTo(param?.produtora),
            
            banco: this._mapperBanco.mapTo(param?.banco),
            conta: param?.conta?.toString()?.replace(/\D/g, ''),
            contaDv: param?.conta_dv,
            agencia: param?.agencia?.toString()?.replace(/\D/g, ''),
            agenciaDv: param?.agencia_dv,

            logArquivo: param?.log_arquivo?.map(arquivo => this._mapperLogArquivoInscricao.mapTo(arquivo)),
        };
    }
}