import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { DocumentacaoModel } from './documentacao.model';
import { DocumentacaoTipoPessoaMapper } from '../documentacao-tipo-pessoa/documentacao-tipo-pessoa.mapper';
import { DocumentacaoEntity } from '@domain/entities/documentacao/documentacao.entity';
import { Mapper } from "@data/contracts/mapper.contract";
import { PerfilEnum } from '@shared/enum/perfil.enum';

@Injectable()
export class DocumentacaoMapper implements Mapper<DocumentacaoModel, DocumentacaoEntity> {
    
    constructor(
        private readonly _datePipe: DatePipe,
        private readonly _mapperDocumentacaoTipoPessoa: DocumentacaoTipoPessoaMapper,
    ) { }

    mapFrom(param: DocumentacaoEntity): DocumentacaoModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            descricao: param?.descricao,
            ativo: param?.ativo,
            obrigatorio: param?.obrigatorio,
            exibe_cadastro_principal: param?.exibeCadastroPrincipal,
            pertence_produtora: param?.pertenceProdutora,
            chave_documento: param?.chaveDocumento,
            tem_validade: param?.temValidade,
            documentacao_tipo_pessoa: this._mapperDocumentacaoTipoPessoa.mapFrom(param?.documentacaoTipoPessoa),

            arquivo: param?.arquivo,
            validade: param?.validade ? this._datePipe.transform(param?.validade, 'yyyy-MM-dd') : undefined,
        };
    }

    mapTo(param: DocumentacaoModel): DocumentacaoEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            descricao: param?.descricao,
            ativo: !!param?.ativo,
            obrigatorio: !!param?.obrigatorio,
            exibeCadastroPrincipal: !!param?.exibe_cadastro_principal,
            pertenceProdutora: !!param?.pertence_produtora,
            chaveDocumento: param?.chave_documento,
            temValidade: !!param?.tem_validade,
            documentacaoTipoPessoa: this._mapperDocumentacaoTipoPessoa.mapTo(param?.documentacao_tipo_pessoa),
            
            arquivo: param?.arquivo,
            validade: param?.validade ? this._datePipe.transform(param?.validade, 'yyyy-MM-dd') : undefined,
            pertenceArtista: param?.documentacao_tipo_pessoa?.perfil?.id ? param?.documentacao_tipo_pessoa?.perfil?.id === PerfilEnum.ARTISTA : undefined,
        };
    }
}