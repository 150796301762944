import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import * as CryptoJS from 'crypto-js';
import { Helps } from './../functions/helps';
import { LocalStorage } from '@infra/storage/local-storage';
import { StorageKey } from '@infra/storage/storage-key';
import { TokenContract } from './../contracts/token.contract';
import { UserEntity } from '@domain/entities/user/user.entity';

@Injectable({
    providedIn: 'root'
})
export class SessionAuthed {
    private tokenSubject$: BehaviorSubject<string | null> = new BehaviorSubject(null);
    private userAuth$: BehaviorSubject<UserEntity | null> = new BehaviorSubject(null);
    private token: TokenContract;

    constructor(
        private readonly _storage: LocalStorage,
    ) {
        this.gerarTokenSession();
        this.sessionToWatch();
        this.checkSession();
    }

    setUserAuth(user: UserEntity): void {
        this.userAuth$.next(user);
    }

    getUserAuth(): UserEntity {
        return this.userAuth$.getValue();
    }

    getUserAuthObservable(): Observable<UserEntity> {
        return this.userAuth$.asObservable();
    }

    setKey(key: string, token?: string): void {
        this.token.confirmation = this.encrypt(`${this.token.order[1]}%${key}#${this.token.order[0]}`);
        if(token) {
            this.token.token = token;
            this.putToken();
        }

        this.checkSession();
    }

    checkSession(): boolean {
        this.configSession();
        this.keyUnique();

        if(!this.tokenSubject$.getValue() && this.token.confirmation === null) {
            return false;
        }

        if(!this.tokenSubject$.getValue() && this.token.token !== null && this.token.confirmation !== null) {
            this.tokenSubject$.next(this.token.session);
            return true;
        }

        if(this.tokenSubject$.getValue() != this.token.session) {
            this.tokenSubject$.next('logout');
            return false;
        }

        return true;
    }

    sessionToWatch(): Observable<string> {
        return this.tokenSubject$.asObservable();
    }

    clear(): void {
        this.newKey();
        this.tokenSubject$.next(null);
        this._storage.clear();
        this.setUserAuth(null);
        this.gerarTokenSession();
    }

    getTokenApi(): string {
        return this.token.token ?? null;
    }
    
    /**
     * Verifica se a sessão é a mesma
     * @returns boolean
     */
    validationSession(): boolean {
        let storage = this.getToken();
        return this.getTokenApi() && !!(storage[2] && storage[2] == this.getTokenApi());
    }

    logout(): void {
        this.tokenSubject$.next('logout');
    }

    private newKey(): void {
        this.token.key = this.encrypt(((new Date().getTime())).toString(2));
        this.token.order = [
            Helps.generateNumber(0, this.token.key.length), 
            Helps.generateNumber(0, this.token.key.length)
        ];
    }

    private configSession(): void {
        let storage = this.getToken();
        if(storage.length == 5) {
            this.token.order[0] = parseInt(storage[3] ?? '0');
            this.token.order[1] = parseInt(storage[0] ?? '0');
            this.token.key = storage[1]?.length == 64 ? storage[1] : null;
            this.token.token = storage[2] ?? null;
            this.token.confirmation = storage[4]?.length == 64 ? storage[4] : null;
        };
    }

    private putToken(): void {
        let setToken = this.base64([
            this.token.order[1],
            this.token.key,
            this.token.token,
            this.token.order[0],
            this.token.confirmation,
        ].join('#'));
        return this._storage.set(StorageKey.token, setToken);
    }

    private getToken(): string[] {
        let restore = this.base64(this._storage.get(StorageKey.token) ?? '', 'decode');
        return restore.split('#');
    }

    private keyUnique(): void {
        let keyDefined = Helps.stringAdicional(this.token.key, this.token.confirmation, this.token.order[0]);
        this.token.session = CryptoJS.SHA256(Helps.stringAdicional(keyDefined, this.token.token, this.token.order[1])).toString();
    }

    private encrypt(valor: string) {
        return CryptoJS.SHA256(valor).toString();
    }

    private base64(dados: string, code: 'encode' | 'decode' = 'encode'): string {
        if(code == 'decode' && !(/^[A-Za-z0-9+/=]*$/.test(dados) && dados.length % 4 === 0)) return '';

        return code == 'encode' ? 
                    CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(dados)) : 
                    CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(dados));
    }

    private gerarTokenSession(): void {
        let key = this.encrypt(((new Date().getTime())).toString(2));

        this.token = {
            token: null,
            key: key,
            order: [
                Helps.generateNumber(0, key.length), 
                Helps.generateNumber(0, key.length)
            ],
            confirmation: null,
            session: null
        };
    }
}