export enum PerfilEnum {
    ARTISTA = 1,
    PRODUTORA = 2,
    AVALIADOR = 3,
    ADMIN = 4,
}

export enum PerfilCleanEnum {
    ARTISTA = 'ARTISTA',
    PRODUTORA = 'PRODUTORA',
    AVALIADOR = 'AVALIADOR',
    ADMIN = 'ADMIN',
}