import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { DocumentacaoRepository } from '@domain/repositories/documentacao/documentacao.repository';
import { DocumentacaoEntity } from '@domain/entities/documentacao/documentacao.entity';

@Injectable()
export class GetAllDocumentacaoByPerfilAndTipoPessoaUseCase implements Usecase<number, DocumentacaoEntity[]> {

    constructor(
        private readonly _repository: DocumentacaoRepository
    ) { }

    execute(idPerfil: number, idTipoPessoa: number): Observable<DocumentacaoEntity[] | Failure> {
        return this._repository.getAllDocumentacaoByPerfilAndTipoPessoa(idPerfil, idTipoPessoa).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<DocumentacaoEntity[]>) => res.right)
        );
    }
}