import { Pipe, PipeTransform } from '@angular/core';

import { phoneFormat } from '@shared/utils/utils';

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {

    transform(tel: string) {
        return phoneFormat(tel)
    }
}
