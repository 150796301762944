import { Observable } from 'rxjs';

import { InscricaoEntity } from '@domain/entities/inscricao/inscricao.entity';
import { SuccessVoid } from '@infra/success/success';

export abstract class InscricaoRemoteDataSource {
    abstract deleteInscricaoByIdPublic(idPublic: string): Observable<SuccessVoid>;
    abstract restoreInscricaoByIdPublic(idPublic: string): Observable<SuccessVoid>;
    abstract getInscricaoUltimaEtapaByIdPublic(idPublic: string): Observable<InscricaoEntity>;
    abstract updateInscricaoLogByIdPublic(param: InscricaoEntity, idPublic: string): Observable<SuccessVoid>;
}