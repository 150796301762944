import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { Failure } from '@infra/contracts/failure.contract';
import { MunicipioRemoteDataSource } from "@data/data-source/municipio/municipio-remote.datasource";
import { MunicipioRepository } from "@domain/repositories/municipio/municipio.repository";
import { MunicipioEntity } from "@domain/entities/municipio/municipio.entity";

@Injectable()
export class MunicipioRepositoryImpl implements MunicipioRepository {

    constructor(
        private readonly _remoteDataSource: MunicipioRemoteDataSource,
    ) { }

    getAllMunicipio(): Observable<Either<Failure, MunicipioEntity[]>> {
        return this._remoteDataSource.getAllMunicipio().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }
}