import { Injectable } from '@angular/core';

import { TipoPessoaModel } from './tipo-pessoa.model';
import { TipoPessoaEntity } from '@domain/entities/tipo-pessoa/tipo-pessoa.entity';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class TipoPessoaMapper implements Mapper<TipoPessoaModel, TipoPessoaEntity> {
    mapFrom(param: TipoPessoaEntity): TipoPessoaModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            sigla: param?.sigla?.toUpperCase(),
            descricao: param?.descricao,
            ativo: param?.ativo,
        };
    }

    mapTo(param: TipoPessoaModel): TipoPessoaEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            sigla: param?.sigla?.toUpperCase(),
            descricao: param?.descricao,
            ativo: param?.ativo,
        };
    }
}