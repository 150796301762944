export enum PermissaoEnum {
    VISUALIZAR_PERFIL="VISUALIZAR_PERFIL",
    VISUALIZAR_AVALIACAO_INSCRICAO="VISUALIZAR_AVALIACAO_INSCRICAO",
    VISUALIZAR_AVALIACAO_PRODUTORA="VISUALIZAR_AVALIACAO_PRODUTORA",
    VISUALIZAR_CONFIGURACAO_EDITAL="VISUALIZAR_CONFIGURACAO_EDITAL",
    VISUALIZAR_CONFIGURACAO_CATEGORIA="VISUALIZAR_CONFIGURACAO_CATEGORIA",
    VISUALIZAR_DOCUMENTACAO_PRODUTORA="VISUALIZAR_DOCUMENTACAO_PRODUTORA",
    VISUALIZAR_MEU_RESULTADO="VISUALIZAR_MEU_RESULTADO",
    VISUALIZAR_RECURSO="VISUALIZAR_RECURSO",
    VISUALIZAR_RELATORIO_EDITAL="VISUALIZAR_RELATORIO_EDITAL",

    VISUALIZAR_ADMINISTRACAO="VISUALIZAR_ADMINISTRACAO",
    VISUALIZAR_CONFIGURACAO="VISUALIZAR_CONFIGURACAO",
    VISUALIZAR_RELATORIO="VISUALIZAR_RELATORIO",
    VISUALIZAR_AVALIACAO_RECURSO="VISUALIZAR_AVALIACAO_RECURSO",
    VISUALIZAR_EDITAL="VISUALIZAR_EDITAL",
    ATUALIZAR_USUARIO="ATUALIZAR_USUARIO",

    LISTAR_USUARIO_EXTERNO="LISTAR_USUARIO_EXTERNO",
    CADASTRAR_USUARIO_EXTERNO="CADASTRAR_USUARIO_EXTERNO",
    ATUALIZAR_USUARIO_EXTERNO="ATUALIZAR_USUARIO_EXTERNO",

    LISTAR_USUARIO_INTERNO="LISTAR_USUARIO_INTERNO",
    CADASTRAR_USUARIO_INTERNO="CADASTRAR_USUARIO_INTERNO",
    ATUALIZAR_USUARIO_INTERNO="ATUALIZAR_USUARIO_INTERNO",
    
    ATUALIZAR_INSCRICAO_ARTISTA="ATUALIZAR_INSCRICAO_ARTISTA",
}