import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { ArquivoUsuarioModel } from './arquivo-usuario.model';
import { DocumentacaoMapper } from '../documentacao/documentacao.mapper';
import { ArquivoUsuarioEntity } from '@domain/entities/arquivo-usuario/arquivo-usuario.entity';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class ArquivoUsuarioMapper implements Mapper<ArquivoUsuarioModel, ArquivoUsuarioEntity> {
    
    constructor(
        private readonly _datePipe: DatePipe,
        private readonly _mapperDocumentacao: DocumentacaoMapper,
    ) { }
    
    mapFrom(param: ArquivoUsuarioEntity): ArquivoUsuarioModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            valido: param?.valido,
            validade: param?.validade ? this._datePipe.transform(param?.validade, 'yyyy-MM-dd') : undefined,
            documentacao: this._mapperDocumentacao.mapFrom(param?.documentacao)
        };
    }

    mapTo(param: ArquivoUsuarioModel): ArquivoUsuarioEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            valido: param?.valido,
            validade: param?.validade ? this._datePipe.transform(param?.validade, 'yyyy-MM-dd') : undefined,
            documentacao: this._mapperDocumentacao.mapTo(param?.documentacao)
        };
    }
}