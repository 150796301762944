import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthGuard } from './auth/guards/auth.guard';
import { SpinnerService } from './services/spinner.service';
import { HttpSpinnerInterceptor } from './http/http-spinner.interceptor';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        AuthGuard,
        SpinnerService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpSpinnerInterceptor,
            multi: true,
        }
    ]
})
export class InfraModule { }
