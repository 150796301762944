import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { FuseNavigation } from '@fuse/types';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { PermissaoEntity } from '@domain/entities/permissao/permissao.entity';
import { MENU } from '@main/shared/services/permissoes/menu';
import { PermissaoEnum } from '@shared/enum/permissao.enum';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    private permissions: PermissaoEntity[] = [];
    private fullMenu: FuseNavigation[] = [];

    constructor(
        private readonly _fuseNavigationService: FuseNavigationService
    ) {
    }

    hasPermissions(permission: PermissaoEnum | Array<PermissaoEnum>): boolean {
        return this.hasArrayPermission(permission);
    }
    
    save(permissions: PermissaoEntity[]): void {
        this.permissions = permissions;
        this.generateMenu();
    }

    private hasArrayPermission(permissionRequested: Array<PermissaoEnum> | PermissaoEnum): boolean {
        if(Array.isArray(permissionRequested)) {
            return permissionRequested.some((permission: PermissaoEnum) => {
                return _.some(this.permissions, { descricao: permission });
            });
        }

        return _.some(this.permissions, { descricao: permissionRequested });
    }

    private generateMenu(): void {
        this.fullMenu = JSON.parse(JSON.stringify(MENU));
        this.removeItemsMenu(this.fullMenu, this.permissions);

        let menuAux = [{
            id       : 'menu',
            title    : 'Menu',
            type     : 'group',
            children : this.fullMenu
        }];

        this._fuseNavigationService.unregister("main")
        this._fuseNavigationService.register('main', menuAux);
        this._fuseNavigationService.setCurrentNavigation('main');
    }

    private removeItemsMenu(menuFuse: FuseNavigation[], permissions: PermissaoEntity[]): void {
        let i = menuFuse.length;
        while (i--) {
            if(menuFuse[i].id != 'DEFAULT' && !_.some(this.permissions, { descricao: menuFuse[i].id })) {
                menuFuse.splice(i, 1);
                continue;
            }

            menuFuse[i].children && this.removeItemsMenu(menuFuse[i].children, permissions);
        }
    }
}