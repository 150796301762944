import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { DocumentacaoRemoteDataSource } from "@data/data-source/documentacao/documentacao-remote.datasource";
import { DocumentacaoRepository } from "@domain/repositories/documentacao/documentacao.repository";
import { DocumentacaoEntity } from "@domain/entities/documentacao/documentacao.entity";
import { Failure } from '@infra/contracts/failure.contract';

@Injectable()
export class DocumentacaoRepositoryImpl implements DocumentacaoRepository {

    constructor(
        private readonly _remoteDataSource: DocumentacaoRemoteDataSource,
    ) { }

    getAllDocumentacaoByPerfilAndTipoPessoa(idPerfil: number, idTipoPessoa: number): Observable<Either<Failure, DocumentacaoEntity[]>> {
        return this._remoteDataSource.getAllDocumentacaoByPerfilAndTipoPessoa(idPerfil, idTipoPessoa).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }
}