import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { LogArquivoInscricaoEntity } from '@domain/entities/log-arquivo-inscricao/log-arquivo-inscricao.entity';
import { LogArquivoInscricaoModel } from './log-arquivo-inscricao.model';
import { InscricaoMapper } from '../inscricao/inscricao.mapper';
import { DocumentacaoMapper } from '../documentacao/documentacao.mapper';
import { StatusMapper } from '../status/status.mapper';
import { EtapaMapper } from '../etapa/etapa.mapper';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class LogArquivoInscricaoMapper implements Mapper<LogArquivoInscricaoModel, LogArquivoInscricaoEntity> {
    
    constructor(
        private readonly _datePipe: DatePipe,
        private readonly _mapperDocumentacao: DocumentacaoMapper,
        private readonly _mapperStatus: StatusMapper,
        private readonly _mapperEtapa: EtapaMapper,
    ) { }

    mapFrom(param: LogArquivoInscricaoEntity): LogArquivoInscricaoModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            documentacao: this._mapperDocumentacao.mapFrom(param?.documentacao),
            validade: param?.validade ? this._datePipe.transform(param?.validade, 'yyyy-MM-dd') : undefined,
            etapa: this._mapperEtapa.mapFrom(param?.etapa),
            status: this._mapperStatus.mapFrom(param?.status),
            deleted_at: param?.deletedAt,
        };
    }

    mapTo(param: LogArquivoInscricaoModel): LogArquivoInscricaoEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            documentacao: this._mapperDocumentacao.mapTo(param?.documentacao),
            validade: param?.validade ? this._datePipe.transform(param?.validade, 'yyyy-MM-dd') : undefined,
            etapa: this._mapperEtapa.mapTo(param?.etapa),
            status: this._mapperStatus.mapTo(param?.status),
            deletedAt: param?.deleted_at,
        };
    }
}