import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { UserModel } from './user.model';
import { PerfilMapper } from '../perfil/perfil.mapper';
import { PermissaoMapper } from './../permissao/permissao.mapper';
import { TipoPessoaMapper } from '../tipo-pessoa/tipo-pessoa.mapper';
import { TipoEtniaMapper } from '../tipo-etnia/tipo-etnia.mapper';
import { MunicipioMapper } from '../municipio/municipio.mapper';
import { ArquivoUsuarioMapper } from '../arquivo-usuario/arquivo-usuario.mapper';
import { UserEntity } from '@domain/entities/user/user.entity';
import { Mapper } from "@data/contracts/mapper.contract";
import { cnpjCpfFormat } from '@shared/utils/utils';

@Injectable()
export class UserMapper implements Mapper<UserModel, UserEntity> {
    
    constructor(
        private readonly _datePipe: DatePipe,
        private readonly _mapperPermissao: PermissaoMapper,
        private readonly _mapperPerfil: PerfilMapper,
        private readonly _mapperTipoPessoa: TipoPessoaMapper,
        private readonly _mapperTipoEtnia: TipoEtniaMapper,
        private readonly _mapperMunicipio: MunicipioMapper,
        private readonly _mapperArquivoUsuario: ArquivoUsuarioMapper,
    ) { }

    mapFrom(param: UserEntity): UserModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            nome_razao: param?.nomeRazao?.toUpperCase(),
            cpf_cnpj: param?.cpfCnpj?.toString()?.replace(/\D/g, ''),
            email: param?.email?.toLowerCase(),
            email_confirmation: param?.emailConfirm?.toLowerCase(),
            perfil: this._mapperPerfil.mapFrom(param?.perfil),
            permissoes: param?.permissoes?.map(permissao => this._mapperPermissao.mapFrom(permissao)),
            tipo_pessoa: this._mapperTipoPessoa.mapFrom(param?.tipoPessoa),
            social_fantasia: param?.socialFantasia,
            matricula: param?.matricula,
            cargo: param?.cargo,
            contato: param?.contato,
            data_nascimento: param?.dataNascimento ? this._datePipe.transform(param?.dataNascimento, 'yyyy-MM-dd') : undefined,
            tipo_etnia: this._mapperTipoEtnia.mapFrom(param?.tipoEtnia),
            ano_atuacao: param?.anoAtuacao,
            descricao_integrante_grupo: param?.descricaoIntegranteGrupo,
            grupo_formalmente_constituido: param?.grupoFormalmenteConstituido,
            incentivo_estadual: param?.incentivoEstadual,
            municipio: this._mapperMunicipio.mapFrom(param?.municipio),
            critica_opiniao_publica: param?.criticaOpiniaoPublica,
            portifolio: param?.portfolio,
            portifolio_links: param?.portfolioLinks,
            pagamento_produtora: param?.pagamentoProdutora,
            cep: param?.cep,
            bairro: param?.bairro,
            endereco: param?.endereco,
            arquivo: param?.arquivo?.map(arquivo => this._mapperArquivoUsuario.mapFrom(arquivo)) || [] ,
            
            mei: param?.mei,
            status: param?.status,
            primeiro_acesso: param?.primeiroAcesso,
            reset_password: param?.resetPassword,
            deleted_at: this._datePipe.transform(param?.deletedAt, 'yyyy-MM-dd hh:mm:ss') || undefined,
            
            password: param?.password ? param?.password : undefined,
            password_confirmation: param?.password ? param?.passwordConfirm : undefined,
        };
    }
    

    mapTo(param: UserModel): UserEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            nomeRazao: param?.nome_razao?.toUpperCase(),
            cpfCnpj: cnpjCpfFormat(param?.cpf_cnpj),
            email: param?.email?.toLowerCase(),
            emailConfirm: param?.email?.toLowerCase(),
            perfil: this._mapperPerfil.mapTo(param?.perfil),
            permissoes: param?.permissoes?.map(permissao => this._mapperPermissao.mapTo(permissao)) ?? [],
            tipoPessoa: this._mapperTipoPessoa.mapTo(param?.tipo_pessoa),
            socialFantasia: param?.social_fantasia,
            matricula: param?.matricula,
            cargo: param?.cargo,
            contato: param?.contato,
            dataNascimento: this._datePipe.transform(param?.data_nascimento, 'yyyy-MM-dd'),
            tipoEtnia: this._mapperTipoEtnia.mapTo(param?.tipo_etnia),
            anoAtuacao: param?.ano_atuacao,
            descricaoIntegranteGrupo: (param?.descricao_integrante_grupo && param?.descricao_integrante_grupo != 'null') ? param?.descricao_integrante_grupo : null,
            grupoFormalmenteConstituido: param?.grupo_formalmente_constituido,
            incentivoEstadual: param?.incentivo_estadual,
            municipio: this._mapperMunicipio.mapTo(param?.municipio),
            criticaOpiniaoPublica: (param?.critica_opiniao_publica && param?.critica_opiniao_publica != 'null') ? param?.critica_opiniao_publica : null,
            portfolio: param?.portifolio,
            portfolioLinks: param?.portifolio_links,
            pagamentoProdutora: param?.pagamento_produtora,
            cep: param?.cep,
            bairro: param?.bairro,
            endereco: param?.endereco,
            arquivo: param?.arquivo?.map(arquivo => this._mapperArquivoUsuario.mapTo(arquivo)) || [],
            
            mei: param?.mei,
            status: param?.status,
            primeiroAcesso: param?.primeiro_acesso,
            resetPassword: param?.reset_password,
            deletedAt: this._datePipe.transform(param?.deleted_at, 'yyyy-MM-dd hh:mm:ss'),

            atualizarPassword: false,
            password: undefined,
            passwordConfirm: undefined,
        };
    }
    
}