import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { ArquivoRemoteDataSource } from "@data/data-source/arquivo/arquivo-remote.datasource";
import { ArquivoRepository } from "@domain/repositories/arquivo/arquivo.repository";
import { Failure } from '@infra/contracts/failure.contract';
import { ArquivoFonteEnum } from "@shared/enum/arquivo-fonte.enum";

@Injectable()
export class ArquivoRepositoryImpl implements ArquivoRepository {

    constructor(
        private readonly _remoteDataSource: ArquivoRemoteDataSource,
    ) { }

    getArquivoByIdPublicAndFonte(idPublic: string, fonte: ArquivoFonteEnum): Observable<Either<Failure, Blob>> {
        return this._remoteDataSource.getArquivoByIdPublicAndFonte(idPublic, fonte).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map((res) => right(res)),
        );
    }

    getSelfArquivoByIdPublicAndFonte(idPublic: string, fonte: ArquivoFonteEnum): Observable<Either<Failure, Blob>> {
        return this._remoteDataSource.getSelfArquivoByIdPublicAndFonte(idPublic, fonte).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map((res) => right(res)),
        );
    }
}