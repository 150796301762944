import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BancoEntity } from '@domain/entities/banco/banco.entity';
import { BancoModel } from '@data/models/banco/banco.model';
import { BancoMapper } from '@data/models/banco/banco.mapper';
import { BancoRemoteDataSource } from './banco-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';

@Injectable()
export class BancoRemoteDataSourceImpl implements BancoRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: BancoMapper
    ) { }

    getAllBanco(): Observable<BancoEntity[]> {
        return this._http.get<BancoModel[]>(`${Endpoints.banco}`).pipe(
            map(res => res['data']?.map(banco => this._mapper.mapTo(banco)))
        );
    }
}