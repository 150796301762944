import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { PerfilRemoteDataSource } from "@data/data-source/perfil/perfil-remote.datasource";
import { PerfilRepository } from "@domain/repositories/perfil/perfil.repository";
import { PerfilEntity } from "@domain/entities/perfil/perfil.entity";
import { Failure } from '@infra/contracts/failure.contract';

@Injectable()
export class PerfilRepositoryImpl implements PerfilRepository {

    constructor(
        private readonly _remoteDataSource: PerfilRemoteDataSource,
    ) { }

    getAllPerfilStatusAtivo(): Observable<Either<Failure, PerfilEntity[]>> {
        return this._remoteDataSource.getAllPerfilStatusAtivo().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }
}