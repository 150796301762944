import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AuthRepository } from '@domain/repositories/auth/auth.repository';
import { AuthRepositoryImpl } from './repositories/auth/auth.repository-impl';
import { AuthUserRemoteDataSource } from './data-source/auth/auth-user-remote.datasource';
import { AuthUserRemoteDataSourceImpl } from './data-source/auth/auth-user-remote.datasource-impl';
import { AuthUserLocalDataSourceImpl } from './data-source/auth/auth-user-local.datasource-impl';
import { AuthUserLocalDataSource } from './data-source/auth/auth-user-local.datasource';

import { UserRepository } from '@domain/repositories/user/user.repository';
import { UserRepositoryImpl } from './repositories/user/user.repository-impl';
import { UserRemoteDataSource } from './data-source/user/user-remote.datasource';
import { UserRemoteDataSourceImpl } from './data-source/user/user-remote.datasource-impl';

import { InscricaoRepository } from '@domain/repositories/inscricao/inscricao.repository';
import { InscricaoRepositoryImpl } from './repositories/inscricao/inscricao.repository-impl';
import { InscricaoRemoteDataSource } from './data-source/inscricao/inscricao-remote.datasource';
import { InscricaoRemoteDataSourceImpl } from './data-source/inscricao/inscricao-remote.datasource-impl';

import { TipoEtniaRepository } from '@domain/repositories/tipo-etnia/tipo-etnia.repository';
import { TipoEtniaRepositoryImpl } from './repositories/tipo-etnia/tipo-etnia.repository-impl';
import { TipoEtniaRemoteDataSource } from './data-source/tipo-etnia/tipo-etnia-remote.datasource';
import { TipoEtniaRemoteDataSourceImpl } from './data-source/tipo-etnia/tipo-etnia-remote.datasource-impl';

import { MunicipioRepository } from '@domain/repositories/municipio/municipio.repository';
import { MunicipioRepositoryImpl } from './repositories/municipio/municipio.repository-impl';
import { MunicipioRemoteDataSource } from './data-source/municipio/municipio-remote.datasource';
import { MunicipioRemoteDataSourceImpl } from './data-source/municipio/municipio-remote.datasource-impl';

import { TipoPessoaRepository } from '@domain/repositories/tipo-pessoa/tipo-pessoa.repository';
import { TipoPessoaRepositoryImpl } from './repositories/tipo-pessoa/tipo-pessoa.repository-impl';
import { TipoPessoaRemoteDataSource } from './data-source/tipo-pessoa/tipo-pessoa-remote.datasource';
import { TipoPessoaRemoteDataSourceImpl } from './data-source/tipo-pessoa/tipo-pessoa-remote.datasource-impl';

import { DocumentacaoRepository } from '@domain/repositories/documentacao/documentacao.repository';
import { DocumentacaoRepositoryImpl } from './repositories/documentacao/documentacao.repository-impl';
import { DocumentacaoRemoteDataSource } from './data-source/documentacao/documentacao-remote.datasource';
import { DocumentacaoRemoteDataSourceImpl } from './data-source/documentacao/documentacao-remote.datasource-impl';

import { ArquivoRepository } from '@domain/repositories/arquivo/arquivo.repository';
import { ArquivoRepositoryImpl } from './repositories/arquivo/arquivo.repository-impl';
import { ArquivoRemoteDataSource } from './data-source/arquivo/arquivo-remote.datasource';
import { ArquivoRemoteDataSourceImpl } from './data-source/arquivo/arquivo-remote.datasource-impl';

import { PerfilRepository } from '@domain/repositories/perfil/perfil.repository';
import { PerfilRepositoryImpl } from './repositories/perfil/perfil.repository-impl';
import { PerfilRemoteDataSource } from './data-source/perfil/perfil-remote.datasource';
import { PerfilRemoteDataSourceImpl } from './data-source/perfil/perfil-remote.datasource-impl';

import { BancoRepository } from '@domain/repositories/banco/banco.repository';
import { BancoRepositoryImpl } from './repositories/banco/banco.repository-impl';
import { BancoRemoteDataSource } from './data-source/banco/banco-remote.datasource';
import { BancoRemoteDataSourceImpl } from './data-source/banco/banco-remote.datasource-impl';

import { AuthMapper } from './models/auth/auth.mapper';
import { PaginatorMapper } from './models/paginator/paginator.mapper';
import { PerfilMapper } from './models/perfil/perfil.mapper';
import { UserMapper } from './models/user/user.mapper';
import { PermissaoMapper } from './models/permissao/permissao.mapper';
import { TipoPessoaMapper } from './models/tipo-pessoa/tipo-pessoa.mapper';
import { TipoEtniaMapper } from './models/tipo-etnia/tipo-etnia.mapper';
import { MunicipioMapper } from './models/municipio/municipio.mapper';
import { DocumentacaoMapper } from './models/documentacao/documentacao.mapper';
import { ArquivoUsuarioMapper } from './models/arquivo-usuario/arquivo-usuario.mapper';
import { DocumentacaoTipoPessoaMapper } from './models/documentacao-tipo-pessoa/documentacao-tipo-pessoa.mapper';
import { InscricaoMapper } from './models/inscricao/inscricao.mapper';
import { InscricaoCategoriaMapper } from './models/inscricao-categoria/inscricao-categoria.mapper';
import { CategoriaEditalMapper } from './models/categoria-edital/categoria-edital.mapper';
import { CategoriaMapper } from './models/categoria/categoria.mapper';
import { EditalMapper } from './models/edital/edital.mapper';
import { StatusMapper } from './models/status/status.mapper';
import { EtapaMapper } from './models/etapa/etapa.mapper';
import { BancoMapper } from './models/banco/banco.mapper';
import { LogArquivoInscricaoMapper } from './models/log-arquivo-inscricao/log-arquivo-inscricao.mapper';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    DatePipe,

    AuthMapper,
    PaginatorMapper,
    PerfilMapper,
    PermissaoMapper,
    UserMapper,
    TipoPessoaMapper,
    TipoEtniaMapper,
    MunicipioMapper,
    DocumentacaoMapper,
    ArquivoUsuarioMapper,
    DocumentacaoTipoPessoaMapper,
    InscricaoMapper,
    InscricaoCategoriaMapper,
    CategoriaEditalMapper,
    CategoriaMapper,
    EditalMapper,
    StatusMapper,
    EtapaMapper,
    BancoMapper,
    LogArquivoInscricaoMapper,

    { provide: AuthUserLocalDataSource, useClass: AuthUserLocalDataSourceImpl },

    { provide: AuthRepository, useClass: AuthRepositoryImpl },
    { provide: AuthUserRemoteDataSource, useClass: AuthUserRemoteDataSourceImpl },

    { provide: UserRepository, useClass: UserRepositoryImpl },
    { provide: UserRemoteDataSource, useClass: UserRemoteDataSourceImpl },

    { provide: InscricaoRepository, useClass: InscricaoRepositoryImpl },
    { provide: InscricaoRemoteDataSource, useClass: InscricaoRemoteDataSourceImpl },

    { provide: TipoEtniaRepository, useClass: TipoEtniaRepositoryImpl },
    { provide: TipoEtniaRemoteDataSource, useClass: TipoEtniaRemoteDataSourceImpl },

    { provide: MunicipioRepository, useClass: MunicipioRepositoryImpl },
    { provide: MunicipioRemoteDataSource, useClass: MunicipioRemoteDataSourceImpl },

    { provide: TipoPessoaRepository, useClass: TipoPessoaRepositoryImpl },
    { provide: TipoPessoaRemoteDataSource, useClass: TipoPessoaRemoteDataSourceImpl },

    { provide: DocumentacaoRepository, useClass: DocumentacaoRepositoryImpl },
    { provide: DocumentacaoRemoteDataSource, useClass: DocumentacaoRemoteDataSourceImpl },

    { provide: ArquivoRepository, useClass: ArquivoRepositoryImpl },
    { provide: ArquivoRemoteDataSource, useClass: ArquivoRemoteDataSourceImpl },

    { provide: PerfilRepository, useClass: PerfilRepositoryImpl },
    { provide: PerfilRemoteDataSource, useClass: PerfilRemoteDataSourceImpl },

    { provide: BancoRepository, useClass: BancoRepositoryImpl },
    { provide: BancoRemoteDataSource, useClass: BancoRemoteDataSourceImpl },
  ]
})
export class DataModule { }
