import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { MunicipioRepository } from '@domain/repositories/municipio/municipio.repository';
import { MunicipioEntity } from '@domain/entities/municipio/municipio.entity';

@Injectable()
export class GetAllMunicipioUseCase implements Usecase<void, MunicipioEntity[]> {

    constructor(
        private readonly _repository: MunicipioRepository
    ) { }

    execute(): Observable<MunicipioEntity[] | Failure> {
        return this._repository.getAllMunicipio().pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<MunicipioEntity[]>) => res.right)
        );
    }
}