import { environment } from './../../../../environments/environment';

export class Endpoints {
    static readonly baseUrl = environment.api;
    
    static readonly auth = `${Endpoints.baseUrl}/auth`;
    static readonly usuario = `${Endpoints.baseUrl}/usuario`;
    static readonly user = `${Endpoints.baseUrl}/admin/usuarios`;
    static readonly produtora = `${Endpoints.baseUrl}/produtora`;
    static readonly etnia = `${Endpoints.baseUrl}/etnia`;
    static readonly banco = `${Endpoints.baseUrl}/bancos`;
    static readonly pessoa = `${Endpoints.baseUrl}/pessoa`;
    static readonly perfil = `${Endpoints.baseUrl}/perfil`;
    static readonly documentacao = `${Endpoints.baseUrl}/documentacao`;
    static readonly municipio = `${Endpoints.baseUrl}/municipios`;
    static readonly inscricao = `${Endpoints.baseUrl}/admin/inscricao`;
    static readonly adminInscricao = `${Endpoints.baseUrl}/admin/inscricao`;
}