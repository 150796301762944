import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@infra/auth/guards/auth.guard';
import { GetUserAuthGuard } from '@infra/auth/guards/get-user-auth.guard';
import { AppComponent } from './app.component';

const routes: Routes = [
  { path: 'mensagem', loadChildren: () => import('./main/shared/components/mensagens/mensagens.module').then(m => m.MensagensModule) },
  { path: 'autenticacao', loadChildren: () => import('./main/autenticacao/autenticacao.module').then(m => m.AutenticacaoModule) },  
  { path: 'cadastro', loadChildren: () => import('./main/cadastro-externo/cadastro-externo.module').then(m => m.CadastroExternoModule) },

  { path: '', redirectTo: "autenticacao", pathMatch: "full" },
  {
    path: '',
    canActivate: [AuthGuard, GetUserAuthGuard],
    children: [
      { path: 'temp', loadChildren: () => import('./main/area-usuario/area-usuario.module').then(m => m.AreaUsuarioModule)},
      { path: 'area-usuario', loadChildren: () => import('./main/area-usuario/area-usuario.module').then(m => m.AreaUsuarioModule)},
      { path: 'inscricoes', loadChildren: () => import('./main/inscricoes/inscricoes.module').then(m => m.InscricoesModule)},
      { path: 'avaliacoes', loadChildren: () => import('./main/avaliacao/avaliacao.module').then(m => m.AvaliacaoModule)},
      { path: 'avaliacoes-recurso', loadChildren: () => import('./main/avaliacao-recurso/avaliacao-recurso.module').then(m => m.AvaliacaoRecursoModule)},
      { path: 'avaliacoes-produtora', loadChildren: () => import('./main/avaliacao-produtora/avaliacao-produtora.module').then(m => m.AvaliacaoProdutoraModule)},
      { path: 'documentacao', loadChildren: () => import('./main/documentacao/documentacao.module').then(m => m.DocumentacaoModule)},
      { path: 'recurso-usuario', loadChildren: () => import('./main/recurso-usuario/recurso-usuario.module').then(m => m.RecursoUsuarioModule)},
      { path: 'relatorio', loadChildren: () => import('./main/relatorio/relatorio.module').then(m => m.RelatorioModule)},
      { path: 'meu-resultado', loadChildren: () => import('./main/meu-resultado/meu-resultado.module').then(m => m.MeuResultadoModule)},
      { path: 'configuracao', loadChildren: () => import('./main/configuracao/configuracao.module').then(m => m.ConfiguracaoModule)},

      { path: 'administracao', loadChildren: () => import('./main/modules/administracao/administracao.module').then(m => m.AdministracaoModule)},
      { path: 'cadastro', loadChildren: () => import('./main/modules/cadastro/cadastro.module').then(m => m.CadastroModule)},
    ]
  },
  { path: '**', redirectTo: "autenticacao", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
