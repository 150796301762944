import { Injectable } from "@angular/core";

import { AuthModel } from "./auth.model";
import { Mapper } from "@data/contracts/mapper.contract";
import { AuthEntity } from '@domain/entities/auth/auth.entity';

@Injectable()
export class AuthMapper implements Mapper<AuthModel, AuthEntity> {
    mapFrom(param: AuthEntity): AuthModel {
        return {
            email: param?.email,
            password: param?.password,
            password_confirmation: param?.passwordConfirmation,
            current_password: param?.currentPassword,
            token: param?.token,
        };
    }

    mapTo(param: AuthModel): AuthEntity {
        return {
            email: param?.email,
            password: param?.password,
            passwordConfirmation: param?.password_confirmation,
            currentPassword: param?.current_password,
            token: param?.token,
        };
    }
}