import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Tokens } from '../models/tokens';
import { environment } from './../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly SANCTUM_TOKEN = 'accessToken';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private _loggedUser: string | null;
  private _http: HttpClient;
  private _baseUrl: String = "auth";

  constructor (
    private _httpBackend: HttpBackend, 
    private httpClient: HttpClient
  ) { 
    this._http = new HttpClient(_httpBackend);
  }

  login(user: { email: string, password: string }): Observable<any> {
    return this._http.post<any>(`${environment.api}/${this._baseUrl}/login`, user)
      .pipe(
        tap(tokens => this.doLoginUser(user.email, tokens)),
      );
  }

  recuperaUsuario(){
    return this.httpClient.get<any>(`${environment.api}/${this._baseUrl}/get-authenticated-user`)
  }
  
  logout() {
    return this.httpClient.post<any>(`${environment.api}/${this._baseUrl}/logout`, {}).pipe(
        finalize(() => this.doLogoutUser()),
        tap(() => this.doLogoutUser()),
    );
  }

  isLoggedIn() {
    return !!this.getSanctumToken();
  }

  refreshToken() {
    return this._http.post<any>(`${environment.api}/${this._baseUrl}/refresh`, {
      'refreshToken': this.getRefreshToken()
    }).pipe(tap((tokens: Tokens) => {
      this.storeSanctumToken(tokens.token);
    }));
  }

  getSanctumToken(): string {
    return sessionStorage.getItem(this.SANCTUM_TOKEN) ?? '';
  }

  private doLoginUser(email: string, tokens: Tokens) {
    this._loggedUser = email;
    this.storeTokens(tokens);
  }

  private doLogoutUser() {
    this._loggedUser = null;
    this.removeTokens();
  }

  private getRefreshToken() {
    return sessionStorage.getItem(this.SANCTUM_TOKEN);
  }

  private storeSanctumToken(sanctum: string) {
    sessionStorage.setItem(this.SANCTUM_TOKEN, sanctum);
  }

  private storeTokens(tokens: Tokens) {
    if (tokens.token) {
      sessionStorage.setItem(this.SANCTUM_TOKEN, tokens.token);
    }
  }

  private removeTokens() {
    sessionStorage.removeItem(this.SANCTUM_TOKEN);
  }
}
