import { Injectable } from '@angular/core';

import { PerfilModel } from './perfil.model';
import { Mapper } from "@data/contracts/mapper.contract";
import { PerfilEntity } from '@domain/entities/perfil/perfil.entity';

@Injectable()
export class PerfilMapper implements Mapper<PerfilModel, PerfilEntity> {
    mapFrom(param: PerfilEntity): PerfilModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            descricao: param?.descricao?.toUpperCase(),
            status: param?.status,
        };
    }

    mapTo(param: PerfilModel): PerfilEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            descricao: param?.descricao?.toUpperCase(),
            status: param?.status,
        };
    }
}