import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArquivoRemoteDataSource } from './arquivo-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { ArquivoFonteEnum } from '@shared/enum/arquivo-fonte.enum';

@Injectable()
export class ArquivoRemoteDataSourceImpl implements ArquivoRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
    ) { }

    getArquivoByIdPublicAndFonte(idPublic: string, fonte: ArquivoFonteEnum): Observable<Blob> {
        return this._http.get(`${Endpoints.user}/arquivo/${idPublic}/${fonte}`, { 
            headers: new HttpHeaders({
                'Content-Type': 'application/pdf',
            }), 
            responseType: 'blob' 
        }).pipe(
            map((res) => res)
        );
    }

    getSelfArquivoByIdPublicAndFonte(idPublic: string, fonte: ArquivoFonteEnum): Observable<Blob> {
        return this._http.get(`${Endpoints.usuario}/arquivo/${idPublic}/${fonte}`, { 
            headers: new HttpHeaders({
                'Content-Type': 'application/pdf',
            }), 
            responseType: 'blob' 
        }).pipe(
            map((res) => res)
        );
    }
}