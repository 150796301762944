import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserRemoteDataSource } from './user-remote.datasource';
import { UserMapper } from '@data/models/user/user.mapper';
import { UserModel } from '@data/models/user/user.model';
import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';
import { UserEntity } from '@domain/entities/user/user.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { SuccessVoid } from '@infra/success/success';
import { PaginatorParam } from '@shared/utils/paginator';
import { HttpUtils } from '@shared/utils/http-utils';
import { UsuarioService } from '@shared/services/usuario/usuario.service';

@Injectable()
export class UserRemoteDataSourceImpl implements UserRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: UserMapper,
        private readonly _mapperPaginator: PaginatorMapper<UserEntity[]>,
        private readonly _usuarioService: UsuarioService, //Manter as coisas antigas funcionando como era na V1.
    ) { }

    getUserAuth(): Observable<UserEntity> {
        return this._http.get<UserModel>(`${Endpoints.auth}/get-authenticated-user`).pipe(
            map(res => {
                this._usuarioService.usuario$.next(res['data']);
                return this._mapper.mapTo(res['data']);
            })
        );
    }
    
    /**
     * @param params PaginatorParam
     * @returns Observable<PaginatorEntity<UserEntity[]>>
     */
    getAllUserPaginated(params: PaginatorParam): Observable<PaginatorEntity<UserEntity[]>> {
        return this._http.get<UserModel[]>(`${Endpoints.user}`, { params: HttpUtils.buildParams(params.mapTo()) })
        .pipe(
            map((res) => this._mapperPaginator.mapPaging(
                res['data']?.map(user => this._mapper.mapTo(user)), 
                res['meta']
                )
            )
        );
    }

    updateStatusUserByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.user}/${idPublic}/status`, { status: status }).pipe(
            map(() => new SuccessVoid())
        );
    }

    updateUserByIdPublic(param: UserEntity, idPublic: string): Observable<SuccessVoid> {
        const dataSubmit = HttpUtils.jsonToFormData(this._mapper.mapFrom(param));

        return this._http.post<SuccessVoid>(`${Endpoints.user}/${idPublic}`, dataSubmit).pipe(
            map(() => new SuccessVoid())
        );
    }

    deleteUserByIdPublic(idPublic: string): Observable<SuccessVoid> {
        return this._http.delete<SuccessVoid>(`${Endpoints.user}/${idPublic}`).pipe(
            map(() => new SuccessVoid())
        );
    }

    restoreUserByIdPublic(idPublic: string): Observable<SuccessVoid> {
        return this._http.patch<SuccessVoid>(`${Endpoints.user}/${idPublic}/restore`, {}).pipe(
            map(() => new SuccessVoid())
        );
    }

    getUserByIdPublic(idPublic: string): Observable<UserEntity> {
        return this._http.get<UserEntity>(`${Endpoints.user}/${idPublic}`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    saveUser(param: UserEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.user}/interno`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    updateSelfUser(param: UserEntity): Observable<SuccessVoid> {
        const dataSubmit = HttpUtils.jsonToFormData(this._mapper.mapFrom(param));

        return this._http.post<SuccessVoid>(`${Endpoints.usuario}/update`, dataSubmit).pipe(
            map(() => new SuccessVoid())
        );
    }

    getMyUser(): Observable<UserEntity> {
        return this._http.get<UserEntity>(`${Endpoints.usuario}/user`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    getUserProdutoraByIdPublic(idPublic: string): Observable<UserEntity> {
        return this._http.get<UserEntity>(`${Endpoints.produtora}`, { params: {
            cpf_cnpj: idPublic
        } }).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }
}