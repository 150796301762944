import { Injectable } from '@angular/core';

import { EtapaEntity } from '@domain/entities/etapa/etapa.entity';
import { EtapaModel } from './etapa.model';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class EtapaMapper implements Mapper<EtapaModel, EtapaEntity> {

    mapFrom(param: EtapaEntity): EtapaModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            codigo: param?.codigo,
            descricao: param?.descricao,
        };
    }

    mapTo(param: EtapaModel): EtapaEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            codigo: param?.codigo,
            descricao: param?.descricao,
        };
    }
}