import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { ServerFailure } from '@infra/failure/failure';
import { AuthRepository } from "@domain/repositories/auth/auth.repository";
import { AuthEntity } from '@domain/entities/auth/auth.entity';
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from '@infra/success/success';
import { AuthUserRemoteDataSource } from "@data/data-source/auth/auth-user-remote.datasource";
import { AuthUserLocalDataSource } from "@data/data-source/auth/auth-user-local.datasource";

@Injectable()
export class AuthRepositoryImpl implements AuthRepository {

    constructor(
        private readonly _remoteDataSource: AuthUserRemoteDataSource,
        private readonly _localDataSource: AuthUserLocalDataSource,
    ) { }

    login(param: AuthEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.login(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(new SuccessVoid())),
        );
    }

    isLogged(): Observable<Either<Failure, boolean>> {
        throw new Error("Method not implemented.");
    }

    logout(): Observable<Either<Failure, SuccessVoid>> {
        this._localDataSource.logout().subscribe();
        return this._remoteDataSource.logout().pipe(
            catchError(() => throwError(left(new ServerFailure('Falha ao realizar logout.')))),
            map(res => right(new SuccessVoid())),
        );
    }

    forgotPassword(param: AuthEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.forgotPassword(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(new SuccessVoid())),
        );
    }

    resetPassword(param: AuthEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.resetPassword(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(new SuccessVoid())),
        );
    }

    changePassword(param: AuthEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.changePassword(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(new SuccessVoid())),
        );
    }
}