import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocumentacaoRemoteDataSource } from './documentacao-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { DocumentacaoMapper } from '@data/models/documentacao/documentacao.mapper';
import { DocumentacaoEntity } from '@domain/entities/documentacao/documentacao.entity';
import { DocumentacaoModel } from '@data/models/documentacao/documentacao.model';
import { HttpUtils } from '@shared/utils/http-utils';

@Injectable()
export class DocumentacaoRemoteDataSourceImpl implements DocumentacaoRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: DocumentacaoMapper,
    ) { }

    getAllDocumentacaoByPerfilAndTipoPessoa(idPerfil: number, idTipoPessoa: number): Observable<DocumentacaoEntity[]> {
        return this._http.get<DocumentacaoModel[]>(`${Endpoints.documentacao}`, {
            params: HttpUtils.buildParams({'perfil_id': idPerfil, 'tipo_pessoa_id': idTipoPessoa})
        }).pipe(
            map(res => res['data']?.map(documentacao => this._mapper.mapTo(documentacao)))
        );
    }
}