import { Injectable } from "@angular/core";
import { StorageClient } from "../contracts/storage-client.contract";

@Injectable({
    providedIn: 'root'
})
export class LocalStorage extends StorageClient {

    set(key: string, value: any): void {
        localStorage.setItem(
            key, 
            typeof value === 'object' ? JSON.stringify(value) : value
        );
    }

    get(key: string): any {
        return localStorage.getItem(key) ?? null;
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }

    clear(): void {
        localStorage.clear();
    }
}