import { Injectable } from '@angular/core';

import { DocumentacaoTipoPessoaModel } from './documentacao-tipo-pessoa.model';
import { PerfilMapper } from '../perfil/perfil.mapper';
import { TipoPessoaMapper } from '../tipo-pessoa/tipo-pessoa.mapper';
import { DocumentacaoTipoPessoaEntity } from '@domain/entities/documentacao-tipo-pessoa/documentacao-tipo-pessoa.entity';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class DocumentacaoTipoPessoaMapper implements Mapper<DocumentacaoTipoPessoaModel, DocumentacaoTipoPessoaEntity> {
    
    constructor(
        private readonly _mapperPerfil: PerfilMapper,
        private readonly _mapperTipoPessoa: TipoPessoaMapper,
    ) { }

    mapFrom(param: DocumentacaoTipoPessoaEntity): DocumentacaoTipoPessoaModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            perfil: this._mapperPerfil.mapFrom(param?.perfil),
            tipo_pessoa: this._mapperTipoPessoa.mapFrom(param?.tipoPessoa),
        };
    }

    mapTo(param: DocumentacaoTipoPessoaModel): DocumentacaoTipoPessoaEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            perfil: this._mapperPerfil.mapTo(param?.perfil),
            tipoPessoa: this._mapperTipoPessoa.mapTo(param?.tipo_pessoa),
        };
    }
}