import { PhonePipe } from './phone/phone.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './truncate/truncate.pipe';
import { CpfCnpjPipe } from './cpf-cnpj/cpf-cnpj.pipe';

@NgModule({
    declarations: [
        PhonePipe,
        TruncatePipe,
        CpfCnpjPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PhonePipe,
        TruncatePipe,
        CpfCnpjPipe
    ]
})
export class PipesModule { }
