import { ErrorMessage } from './../models/error-message';
import { throwError } from 'rxjs';

export function errorHandler(error: any) {

    let msg = ['Não foi possivel concluir a operação'];
    let messageError: Array<string> = errorObjectToArraySimple(error?.error ?? [error.message]);

    if (messageError.length > 0) {
        msg = messageError;
    }

    let failure: ErrorMessage = {
        descriptions: msg
    }
  
    return throwError(failure);
}

function errorObjectToArraySimple(objeto: any): Array<string> {
    let arrayResultado = [];
  
    for (let chave in objeto) {
      if (objeto.hasOwnProperty(chave)) {
        if (Array.isArray(objeto[chave])) {
          arrayResultado.push(...objeto[chave]);
        } else if (typeof objeto[chave] === 'object' && objeto[chave] !== null) {
          const arrayFilho = errorObjectToArraySimple(objeto[chave]);
          arrayResultado.push(...arrayFilho);
        } else {
          arrayResultado.push(objeto[chave]);
        }
      }
    }
  
    return arrayResultado;
}