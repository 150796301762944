import { Injectable } from '@angular/core';

import { BancoEntity } from '@domain/entities/banco/banco.entity';
import { BancoModel } from './banco.model';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class BancoMapper implements Mapper<BancoModel, BancoEntity> {

    mapFrom(param: BancoEntity): BancoModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            codigo: param?.codigo,
            descricao: param?.descricao,
        };
    }

    mapTo(param: BancoModel): BancoEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            codigo: param?.codigo,
            descricao: param?.descricao,
            codigoDescricao: `${param?.codigo} - ${param?.descricao}`,
        };
    }
}