import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { TipoEtniaMapper } from '@data/models/tipo-etnia/tipo-etnia.mapper';
import { TipoEtniaRemoteDataSource } from './tipo-etnia-remote.datasource';
import { TipoEtniaEntity } from '@domain/entities/tipo-etnia/tipo-etnia.entity';
import { TipoEtniaModel } from '@data/models/tipo-etnia/tipo-etnia.model';

@Injectable()
export class TipoEtniaRemoteDataSourceImpl implements TipoEtniaRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: TipoEtniaMapper
    ) { }

    getAllTipoEtnia(): Observable<TipoEtniaEntity[]> {
        return this._http.get<TipoEtniaModel[]>(`${Endpoints.etnia}`).pipe(
            map(res => res['data']?.map(etnia => this._mapper.mapTo(etnia)))
        );
    }
}