import { HttpUtils } from './../../utils/http-utils';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { Usuario } from './usuario.model';
import { errorHandler } from '../../functions/errorHandler';

import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';
import { PaginatorParam } from '@main/shared/utils/paginator';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

    private baseUrl: String = "user";
    public usuario$: BehaviorSubject<Usuario> = new BehaviorSubject(null);

    constructor(
        private _http: HttpClient,
        private readonly _mapperPaginator: PaginatorMapper<Usuario[]>,
    ) {
        
    };

    create(dados: FormData) : Observable<Usuario> {
        return this._http.post<Usuario>(environment.api + `/${this.baseUrl}/register-participant`, dados).pipe(
            catchError(errorHandler),
            map((res)=>res["data"])
        );
    }

    alterarSenha(dados: any): Observable<Usuario> {
        return this._http.post<Usuario>(environment.api + `/change-password`, dados).pipe(
            catchError(errorHandler),
            map((res)=>res["data"])
        );
    }

    recuperarSenha(dados: any): Observable<any> {
        return this._http.post<Usuario>(environment.api + `/forgot-password`, dados).pipe(
            catchError(errorHandler),
            map((res)=>res["data"])
        );
    }
}
