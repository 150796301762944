import { Injectable } from '@angular/core';

import { PermissaoModel } from './permissao.model';
import { Mapper } from "@data/contracts/mapper.contract";
import { PermissaoEntity } from '@domain/entities/permissao/permissao.entity';

@Injectable()
export class PermissaoMapper implements Mapper<PermissaoModel, PermissaoEntity> {
    mapFrom(param: PermissaoEntity): PermissaoModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            nome: param?.nome,
            descricao: param?.descricao,
            status: param?.status,
        };
    }

    mapTo(param: PermissaoModel): PermissaoEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            nome: param?.nome,
            descricao: param?.descricao,
            status: param?.status,
        };
    }
}