import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { FormControlValidatorContract } from './../contracts/form-control-default.contract';
import { AbstractControl } from "@angular/forms";
import { Observable, Observer, Subject } from "rxjs";
import Swal from 'sweetalert2';
import swal from "sweetalert2";
import { MatHorizontalStepper } from '@angular/material/stepper';
import { EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export class Helps {
    static jsonToFormData (json: Object, parentKey: string = ''): FormData {
        return Object.entries(json).reduce((formData: FormData, [key, value]) => {
            const appendToFormData = (data, parentKey = '') => {
                for (let innerKey in data) {
                    if (data.hasOwnProperty(innerKey)) {
                        const nestedKey = parentKey ? `${parentKey}[${innerKey}]` : innerKey;
                        const innerValue = data[innerKey];

                        if(!(innerValue instanceof File) && (Array.isArray(innerValue) || (typeof innerValue === 'object' && innerValue !== null))) {
                            appendToFormData(innerValue, nestedKey);
                        } else formData.append(nestedKey, innerValue);
                    }
                }
            };

            appendToFormData({ [key]: value }, parentKey);

            return formData;
        }, new FormData());
    }

    static cnpjValid(cnpj: string|null) {
        cnpj = (cnpj) ? cnpj.replace(/\D/g, '') : '';

        if (cnpj) {

            if (cnpj == '' || cnpj.length != 14) return false;

            // Elimina CNPJs invalidos conhecidos
            if (cnpj == "00000000000000" ||
                cnpj == "11111111111111" ||
                cnpj == "22222222222222" ||
                cnpj == "33333333333333" ||
                cnpj == "44444444444444" ||
                cnpj == "55555555555555" ||
                cnpj == "66666666666666" ||
                cnpj == "77777777777777" ||
                cnpj == "88888888888888" ||
                cnpj == "99999999999999")
                return false;

            // Valida DVs
            let tamanho = cnpj.length - 2
            let numeros = cnpj.substring(0, tamanho);
            let digitos = cnpj.substring(tamanho);
            let soma = 0;
            let pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += Number(numeros.charAt(tamanho - i)) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != Number(digitos.charAt(0)))
                return false;

            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += Number(numeros.charAt(tamanho - i)) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != Number(digitos.charAt(1)))
                return false;

            return true;

        }

        return false;
    }

    static isValidDate(dateInput: string, toDay: boolean = false) {
        const dateString = dateInput;
        const isDateValid = !isNaN((new Date(dateString)).getTime());
        let partesData = dateString?.split('-');

        if (!isDateValid || partesData?.length !== 3) return false;

        let [ano, mes, dia] = [
            parseInt(partesData[0], 10),
            parseInt(partesData[1], 10) - 1,
            parseInt(partesData[2], 10)
        ];

        const [date, limiteInferior, limiteSuperior] = [
            new Date(ano, mes, dia),
            toDay ? new Date() : new Date(1900, 0, 1),
            new Date(9999, 12, 31)  
        ];

        date.setHours(0, 0, 0, 0);
        limiteInferior.setHours(0, 0, 0, 0);
        let isDateInRange = date >= limiteInferior && date <= limiteSuperior;
        return isDateInRange ? true : false;
    }

    static dataValidade(dateInput: string, toDay: boolean = true): boolean {
        if(dateInput) return this.isValidDate(dateInput, toDay) ? true : false;
        return false;        
    }

    static listaView(titulo: string, lista: Array<string>): void {
        swal.fire({
            title: `${titulo}`,
            html: `<ul>
            ${lista?.map(item => `
                <li>${item}</li>
            `).join('')}
            </ul>`,
            icon: 'info',
            customClass: {
                htmlContainer: 'swal2-text-left'
            }
        });
    }

    static validFilePdf(file, sizeMaxMb = 10): boolean {
        let [
            tipoArquivo, 
            sizeMB
        ] = [
            file.type.replace(/.*\//, ""),
            Number((file.size / (1024*1024)).toFixed(2))
        ];

        if (!tipoArquivo.match(/pdf/)) {
            swal.fire('Formato inválido', 'Somente são aceitos arquivos no formato PDF', 'error');
            return false;
        }

        if (sizeMB > sizeMaxMb) {
            swal.fire(`Tamanho Inválido`, `O tamanho máximo para envio deste arquivo é de ${sizeMaxMb} MB`, 'error');
            return false;
        }
        
        return true;
    }

    static string2bool(value: string): boolean {
        return value == "1" ? true : false;
    }

    static downloadFile(fileDownload: Blob, fileNameExtension: string) {
        const blob = new Blob([fileDownload]);
        const file = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = file;
        downloadLink.download = fileNameExtension;
        downloadLink.click();
        window.URL.revokeObjectURL(file);
    }

    static formatarDocumento(cpfCnpj: string): string {
        cpfCnpj = cpfCnpj.replace(/\D/g, '');
        if (cpfCnpj.length === 11) return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        if (cpfCnpj.length === 14) return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        return '-';
    }

    static convertImageToBase64(path: string, minetype: string): Observable<string> {
        return new Observable((observer: Observer<string>) => {
          const img = new Image();
          img.src = path;
          img.onload = () => {
            try {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext('2d');
              if (ctx) {
                ctx.drawImage(img, 0, 0);
                const base64 = canvas.toDataURL(minetype);
                observer.next(base64);
                observer.complete();
              } else {
                observer.error('Erro ao obter contexto 2D do canvas.');
              }
            } catch (error) {
              observer.error(`Erro ao converter imagem para base64: ${error}`);
            }
          };
          img.onerror = (error) => observer.error(`Erro ao carregar imagem: ${error}`);
        });
    }

    /**
     * Retorna true caso seja inválido.
     * 
     * Um exemplo de como usar `markFormTouched`:
     * 
     * ```
     * const formOrgao = new FormControl(null, Validators.required);
     * markFormTouched(0, [formOrgao])
     * ```
     * 
     * -OR-
     * 
     * ```
     * const formCadastro: FormControl = new FormControl(null, Validators.required);
     * const formCadastro2: FormControl = new FormControl(null, Validators.required);
     * const formOrgao: FormControl = new FormControl(null);
     * const orgaoValidation$: Subject<boolean> = new Subject<boolean>(false);
     * markFormTouched(0, [formCadastro, [formOrgao, orgaoValidation$], formCadastro2])
     * ```
     * 
     * -OBS-
     * 
     * Quando o formulário tiver mais de um item a ser validado, utilizando os componentes
     * customizados, passe dessa froma:
     * ```
     * const orgaoValidation2$: Subject<boolean> = new Subject<boolean>(false);
     * markFormTouched(0, [formCadastro, [formOrgao, orgaoValidation$, orgaoValidation2$], formCadastro2])
     * ```
     * 
     * @param step (EventEmitter<StepperSelectionEvent> | number)
     * @param formControlOrSubject (AbstractControl | Subject<boolean> | [AbstractControl, ...(Subject<boolean>|AbstractControl)[]])[]
     * @returns boolean
     */
    static markFormTouched(
        step: EventEmitter<StepperSelectionEvent> | number, 
        formControlOrSubject: (AbstractControl | Subject<boolean> | [AbstractControl, ...(Subject<boolean>|AbstractControl)[]])[],
        stepper: MatHorizontalStepper = null
    ): boolean {
        let isInvalid = false;

        if(typeof step === 'number' && formControlOrSubject[step]) {
            let isArray = Array.isArray(formControlOrSubject[step]);
            let data: any = isArray ? formControlOrSubject[step] : [formControlOrSubject[step]];

            data.forEach(formSub => {
                if(formSub instanceof AbstractControl) {
                    let form = formSub as AbstractControl;
                    form.markAllAsTouched();
                    if(form.invalid) isInvalid = true;
                }

                if(formSub instanceof Subject) {
                    let sub = formSub as Subject<boolean>;
                    sub.next(true);
                } 
            });
        }

        if(isInvalid) {
            let label = 'Preencha todos os campos destacado de vermelho';
            if(typeof step === 'number' && stepper) {
                stepper.selectedIndex = step;
                let info = stepper.steps.toArray();
                if(info[step]?.label) label +=  ` em <b>${info[step]?.label?.toLowerCase()}</b>`;
            }

            Swal.fire('Dados incompletos', label, 'error');
        }

        return isInvalid;
    }

    /**
     * É utilizado mais para quando for feito alguma alteração e deseja fazer essa ações de uma única vez.
     * 
     * @param form AbstractControl
     * @param formControl FormControlValidatorContract[]
     * @param disabled boolean, desabilita todos, caso seja true.
     */
    static toggleFormControls(form: AbstractControl, formControl: FormControlValidatorContract[], disabled: boolean = false): void {
        formControl.forEach((control, idx) => {
            const formControlData = control?.name ? form.get(control.name) : form;
            
            if(disabled || control?.disabled)
                formControlData?.disable();
            else 
                formControlData?.enable();

            if(control?.options)
                formControlData.setValidators(control?.options);

            if(control.value !== undefined)
                formControlData.setValue(control.value);

            if(control.clearValidators)
                formControlData.clearValidators();

            formControlData.updateValueAndValidity();
        });
    }

    static removerUndefinedAndEmptyObjects<T>(objeto: T | T[], defaultValor?: any) {
        if (Array.isArray(objeto))
            return objeto.map(elemento => this.treatingUndefinedObject(elemento, defaultValor));

        return Object.entries(objeto).reduce((acc, [key, value]) => {
            if (value === undefined) return acc;
            if (value !== null && typeof value === 'object' && !(value instanceof File)) {
                const cleanedValue = this.removerUndefinedAndEmptyObjects(value, defaultValor);
                if (Object.keys(cleanedValue).length > 0)
                    acc[key] = cleanedValue;

                return acc;
            }

            acc[key] = value;
            return acc;
        }, {});
    }

    /**
     * Realizar o tratamento de undefined do objeto, é possível retirar ou substiuir por outro valor
     * 
     * @param objeto 
     * @param defaultValor 
     * @returns 
     */
    static treatingUndefinedObject<T>(objeto: T | T[], defaultValor?: any) {
        if (Array.isArray(objeto))
            return objeto.map(elemento => this.treatingUndefinedObject(elemento, defaultValor));

        if (typeof objeto === 'object' && objeto !== null && !(objeto instanceof File)) {
            return Object.entries(objeto).reduce((propriedade: any, [chave, valor]) => {
                const novoValor = this.treatingUndefinedObject(valor, defaultValor);
                if (novoValor !== undefined)
                    propriedade[chave] = novoValor;

                if(novoValor === undefined && defaultValor !== undefined)
                    propriedade[chave] = defaultValor;
                
                return propriedade;
            }, {});
        }

        return objeto;
    }

    static generateNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;;
    }

    static stringAdicional(str: string, adicional: string, posicao: number): string {
        const parteInicial = str.slice(0, posicao);
        const parteFinal = str.slice(posicao);

        return parteInicial + adicional + parteFinal;
    }

    static redirectRouteRelative(router: Router, route: ActivatedRoute, routeBack: string, temp: boolean = false): void {
        if(temp) {
            router.navigateByUrl('/temp', { skipLocationChange: true }).then(() => {
                router.navigate([routeBack], { relativeTo: route });
            });
            return;
        }

        router.navigate([routeBack], { relativeTo: route });
        return;
    }
}