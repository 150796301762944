import { Injectable } from '@angular/core';

import { MunicipioModel } from './municipio.model';
import { MunicipioEntity } from '@domain/entities/municipio/municipio.entity';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class MunicipioMapper implements Mapper<MunicipioModel, MunicipioEntity> {
    mapFrom(param: MunicipioEntity): MunicipioModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            nome: param?.nome,
        };
    }

    mapTo(param: MunicipioModel): MunicipioEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            nome: param?.nome,
        };
    }
}