import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SpinnerService } from './../services/spinner.service';

@Injectable()
export class HttpSpinnerInterceptor implements HttpInterceptor {

    constructor(
        private readonly _spinner: SpinnerService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this._spinner.show();

        return next.handle(request).pipe(
            finalize(() => {
                this._spinner.hide();
            })
        );
    }
}