import { ClearValueOptions } from '../contracts/clear-value-options';

export function transformNumberToBoolean(value: boolean | number | null): boolean {
    return Boolean(value);
}

export function isString(value: string) {
    return !!value && typeof value === 'string';
}

export function transformStringToArray(value: any) {
    if (isString(value)) {
        return [value];
    }

    return value;
}

export function maskPhone(phone: string = '', maskDefault: string = null): string {
    return maskDefault ? maskDefault : phone?.replace(/\D/g, '')?.length > 10 ? '(00) 0 0000-0000' : '(00) 0000-00009?';
}

export function phoneFormat(tel: string): string {
    if (tel) {
        const value = tel.toString().replace(/\D/g, '');

        let foneFormatado = '';

        if (value.length > 12) {
            foneFormatado = value.replace(/(\d{2})?(\d{2})?(\d{1})?(\d{4})?(\d{4})/, '+$1 ($2) $3 $4-$5');
            return foneFormatado;
        }

        if (value.length > 11) {
            foneFormatado = value.replace(/(\d{2})?(\d{2})?(\d{4})?(\d{4})/, '+$1 ($2) $3-$4');
            return foneFormatado;
        }
        
        if (value.length > 10) {
            foneFormatado = value.replace(/(\d{2})?(\d{1})?(\d{4})?(\d{4})/, '($1) $2 $3-$4');
            return foneFormatado;
        }
        
        if (value.length > 9) {
            foneFormatado = value.replace(/(\d{2})?(\d{4})?(\d{4})/, '($1) $2-$3');
            return foneFormatado;
        }
        
        if (value.length > 5) {
            foneFormatado = value.replace(/^(\d{2})?(\d{4})?(\d{0,4})/, '($1) $2-$3');
            return foneFormatado;
        }
        
        if (value.length > 1) {
            foneFormatado = value.replace(/^(\d{2})?(\d{0,5})/, '($1) $2');
            return foneFormatado;
        } 
        
        if(value.length < 1 && tel !== '') {
            foneFormatado = value.replace(/^(\d*)/, '($1');
            return foneFormatado;
        }

        return foneFormatado;
    }
    return tel;
}

export function cnpjCpfFormat(cpfCnpj: string): string {
    if (cpfCnpj) {
        const value = cpfCnpj?.toString().replace(/\D/g, '');
        if (value.length === 11) return value?.padStart(11, '0').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
        if (value.length === 14) return value?.padEnd(14, '0').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    }

    return cpfCnpj;
}

export function applyMaskNumeric(value: string, mask: string): string {
    if (!value)
        return '';

    let formattedValue = '';
    let valueIndex = 0;

    for (let i = 0; i < mask.length; i++) {
        if (mask[i] === '0') {
            if (value[valueIndex]) {
            formattedValue += value[valueIndex];
            valueIndex++;
            } else {
            break;
            }
        } else {
            formattedValue += mask[i];
        }
    }

    return formattedValue;
}


/**
 * Multiplica os elementos de uma string com os elementos de outra, ou de um array
 * e soma o resultado ao final
 *
 * @example
 *   sumElementsByMultipliers('123', '987')      //-> 46
 *   sumElementsByMultipliers('123', [9, 8, 7])  //-> 46
 *
 * @param {String} value
 * @param {String|Array} multiplier
 * @returns {Integer} Somatório
 */
export function sumElementsByMultipliers(value: string, multiplier: string | number[]): number {
    if (typeof multiplier === 'string')
        multiplier = multiplier.split('').map((n) => Number(n));

    return multiplier.reduce((accu, curr, i) => accu + curr * Number(value.charAt(i)), 0);
}

/**
 * Calcula o DV verificador a partir das regras do MOD11:
 * O valor da soma é dividido por 11. O resultado é o resto da divisão. Caso o resto seja
 * menor que 2, ou seja, o valor da divisão seja 10 ou 11, o resultado é 0.
 *
 * @param {Integer} sum Soma
 * @returns {Integer}
 */
export function sumToDV(sum: number): number {
    return sum % 11 < 2 ? 0 : 11 - (sum % 11);
};

/**
 * Limpa um número informado, retirando caracteres diferentes de números,
 * preenchendo com zeros à esquerda se for menor que o tamanho exato e
 * removendo uma parte do número se for maior que tamanho definido.
 *
 * 1) Retira caracteres não-numéricos
 * 2) Preenche com zeros à esquerda se 'value' for menor que 'length'
 * 3) Remove caracteres à direita se 'value' for maior que 'length'
 *
 * @example
 *  clearValue(12345-6, 6) // -> 123456
 *  clearValue(12345678, 3) // -> 123
 *  clearValue(12345, 10) // -> 0000001234
 *
 * @param {Number|String} value
 * @param {Number} length Tamanho exato. Se for null, só retira os caracteres não-numéricos
 * @returns {String} Número com o tamanho exato
 */
export function clearValue(value: string | number, length?: number | null, options?: ClearValueOptions): string {
    let clearedValue: string = String(value).replace(/([/.-]+)/gi, '');
    if (options) {
        if (options.rejectEmpty === true && clearedValue.length === 0)
            throw 'Valor não preenchido';

        if (options.rejectHigherLength === true && length && clearedValue.length > length)
            throw 'Número de caracteres excedido';

        if (options.rejectEqualSequence === true && length) {
            const invalidList = invalidListGenerator(length);
            if (invalidList.includes(clearedValue)) {
                throw 'Sequência de números repetidos não permitida';
            }
        }

        if (length && options?.fillZerosAtLeft)
            clearedValue = clearedValue.padStart(length, '0');
        if (length && options?.trimAtRight)
            clearedValue = clearedValue.substring(0, length);
    }

    return clearedValue;
}

/**
 * Cria uma lista de valores repetidos no tamanho do documento para eliminar
 * valores que já conhecemos como inválidos
 *
 * @example
 * invalidListGenerator(10, 11)
 * //-> [00000000000, 11111111111, ....., 99999999999]
 *
 * @param {Integer} length Número de itens do array
 * @param {Integer} size Tamanho da string gerada
 * @returns {Array} Lista de valores
 */
export function invalidListGenerator(size: number): string[] {
    return [...Array(10).keys()].map((f) => String(f).repeat(size));
}

export type ProfessorFormType = 'areaInteresse' | 'documentacao' | 'formacao' | 'experiencia';