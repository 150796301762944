import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { PerfilRepository } from '@domain/repositories/perfil/perfil.repository';
import { PerfilEntity } from '@domain/entities/perfil/perfil.entity';

@Injectable()
export class GetAllPerfilStatusAtivoUseCase implements Usecase<void, PerfilEntity[]> {

    constructor(
        private readonly _repository: PerfilRepository
    ) { }

    execute(): Observable<PerfilEntity[] | Failure> {
        return this._repository.getAllPerfilStatusAtivo().pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<PerfilEntity[]>) => res.right)
        );
    }
}