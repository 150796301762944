import { Injectable } from '@angular/core';

import { CategoriaEditalEntity } from '@domain/entities/categoria-edital/categoria-edital.entity';
import { CategoriaEditalModel } from './categoria-edital.model';
import { TipoPessoaMapper } from '../tipo-pessoa/tipo-pessoa.mapper';
import { CategoriaMapper } from '../categoria/categoria.mapper';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class CategoriaEditalMapper implements Mapper<CategoriaEditalModel, CategoriaEditalEntity> {
    
    constructor(
        private readonly _mapperTipoPessoa: TipoPessoaMapper,
        private readonly _mapperCategoria: CategoriaMapper,
    ) { }

    mapFrom(param: CategoriaEditalEntity): CategoriaEditalModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            tipo_pessoa: this._mapperTipoPessoa.mapFrom(param?.tipoPessoa),
            categoria: this._mapperCategoria.mapFrom(param?.categoria),
            
            ativo: !!param?.ativo,
            atracao: param?.atracao?.trim()?.toUpperCase(),
        };
    }

    mapTo(param: CategoriaEditalModel): CategoriaEditalEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            tipoPessoa: this._mapperTipoPessoa.mapTo(param?.tipo_pessoa),
            categoria: this._mapperCategoria.mapTo(param?.categoria),

            ativo: !!param?.ativo,
            atracao: param?.atracao?.trim()?.toUpperCase(),
        };
    }
}