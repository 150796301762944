import { Injectable } from '@angular/core';

import { PaginatorModel } from './paginator.model';
import { Mapper } from "@data/contracts/mapper.contract";
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';

@Injectable()
export class PaginatorMapper<T> implements Mapper<PaginatorModel<T>, PaginatorEntity<T>> {
    mapFrom(param: PaginatorEntity<T>): any {
        return;
    }

    mapTo(param: PaginatorModel<T>): PaginatorEntity<T> {
        return {
            data: param?.data,
            page: param?.meta?.current_page,
            perPage: param?.meta?.per_page,
            lastPage: param?.meta?.last_page,
            total: param?.meta?.total,
        };
    }

    mapPaging(paramData: T[], paramMeta: Object): PaginatorEntity<T> {
        return this.mapTo({
            data: paramData,
            meta: paramMeta,
        })
    }
}