import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { TipoPessoaMapper } from '@data/models/tipo-pessoa/tipo-pessoa.mapper';
import { TipoPessoaRemoteDataSource } from './tipo-pessoa-remote.datasource';
import { TipoPessoaEntity } from '@domain/entities/tipo-pessoa/tipo-pessoa.entity';
import { TipoPessoaModel } from '@data/models/tipo-pessoa/tipo-pessoa.model';

@Injectable()
export class TipoPessoaRemoteDataSourceImpl implements TipoPessoaRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: TipoPessoaMapper
    ) { }

    getAllTipoPessoa(): Observable<TipoPessoaEntity[]> {
        return this._http.get<TipoPessoaModel[]>(`${Endpoints.pessoa}`).pipe(
            map(res => res['data']?.map(etnia => this._mapper.mapTo(etnia)))
        );
    }
}