import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'menu',
        title    : 'Menu',
        type     : 'group',
        children : [
            {
                id: 'menu.dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: 'chevron_right',
                url: '/dashboard'
            },
            {
                id: 'menu.area-usuario',
                title: 'Meus dados',
                type: 'item',
                icon: 'chevron_right',
                url: '/area-usuario'
            },
            {
                id: 'menu.administracao',
                title: 'Administração',
                type: 'item',
                icon: 'chevron_right',
                url: '/administracao'
            },
            {
                id: 'menu.parametrizacao',
                title: 'Parametrização',
                type: 'item',
                icon: 'chevron_right',
                url: '/parametrizacao'
            },
            {
                id: 'menu.avaliacoes',
                title: 'Avaliações',
                type: 'collapsable',
                icon: 'chevron_right',
                children: [
                    {
                        id: 'menu.avaliacoes-configuracao',
                        title: 'Configurações',
                        type: 'item',
                        url: '/avaliacoes'
                    },
                    {
                        id: 'menu.avaliacoes-usuario',
                        title: 'Minhas Avaliações',
                        type: 'item',
                        url: '/avaliacoes-usuario'
                    }
                ]
            }
        ]
    }
];
