import { Injectable } from '@angular/core';

import { TipoEtniaModel } from './tipo-etnia.model';
import { TipoEtniaEntity } from '@domain/entities/tipo-etnia/tipo-etnia.entity';
import { Mapper } from "@data/contracts/mapper.contract";

@Injectable()
export class TipoEtniaMapper implements Mapper<TipoEtniaModel, TipoEtniaEntity> {
    mapFrom(param: TipoEtniaEntity): TipoEtniaModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            descricao: param?.descricao,
        };
    }

    mapTo(param: TipoEtniaModel): TipoEtniaEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            descricao: param?.descricao,
        };
    }
}