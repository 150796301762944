import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { of, throwError } from 'rxjs';
import { catchError, switchMapTo, tap } from 'rxjs/operators';
import swal from 'sweetalert2';

import { GetUserAuthUseCase } from '@domain/usecases/user/get-user-auth.usecase';
import { UserEntity } from '@domain/entities/user/user.entity';
import { Failure } from '@infra/contracts/failure.contract';
import { PermissionsService } from '@infra/services/permissions.service';
import { SessionAuthed } from '@shared/utils/session-authed';

@Injectable({
    providedIn: 'root'
})
export class GetUserAuthGuard implements CanActivate {

    constructor(
        private readonly _getUserAuthUseCase: GetUserAuthUseCase,
        private readonly _session: SessionAuthed,
        private readonly _router: Router,
        private readonly _permissionsService: PermissionsService
    ) {
        
    }

    canActivate() {
        return this._getUserAuthUseCase.execute().pipe(
            tap((res: UserEntity) => {
                this._session.setUserAuth(res);
                this._permissionsService.save(res.permissoes);

                if(res?.resetPassword) {
                    this._router.navigateByUrl('/autenticacao/password/change-password', { replaceUrl: true });
                    return false;
                }
            }),
            switchMapTo(of(true)),
            catchError((error: Failure) => {
                swal.fire('Atenção', error.message, 'warning');
                this._router.navigateByUrl('/login', { replaceUrl: true });
                return throwError('Falha no carregamento dos dados do usuario');
            }
        ));
    }
}
