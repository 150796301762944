import { Observable } from 'rxjs';
import { Either } from 'fp-ts/Either';

import { AuthEntity } from '@domain/entities/auth/auth.entity';
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from '@infra/success/success';

export abstract class AuthRepository {
    abstract login(param: AuthEntity): Observable<Either<Failure, SuccessVoid>>;
    abstract isLogged(): Observable<Either<Failure, boolean>>;
    abstract logout(): Observable<Either<Failure, SuccessVoid>>;

    abstract forgotPassword(param: AuthEntity): Observable<Either<Failure, SuccessVoid>>;
    abstract resetPassword(param: AuthEntity): Observable<Either<Failure, SuccessVoid>>;
    abstract changePassword(param: AuthEntity): Observable<Either<Failure, SuccessVoid>>;
}