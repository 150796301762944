import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { TipoEtniaRepository } from '@domain/repositories/tipo-etnia/tipo-etnia.repository';
import { TipoEtniaEntity } from '@domain/entities/tipo-etnia/tipo-etnia.entity';

@Injectable()
export class GetAllTipoEtniaUseCase implements Usecase<void, TipoEtniaEntity[]> {

    constructor(
        private readonly _repository: TipoEtniaRepository
    ) { }

    execute(): Observable<TipoEtniaEntity[] | Failure> {
        return this._repository.getAllTipoEtnia().pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<TipoEtniaEntity[]>) => res.right)
        );
    }
}