import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { Failure } from '@infra/contracts/failure.contract';
import { TipoPessoaRemoteDataSource } from "@data/data-source/tipo-pessoa/tipo-pessoa-remote.datasource";
import { TipoPessoaRepository } from "@domain/repositories/tipo-pessoa/tipo-pessoa.repository";
import { TipoPessoaEntity } from "@domain/entities/tipo-pessoa/tipo-pessoa.entity";

@Injectable()
export class TipoPessoaRepositoryImpl implements TipoPessoaRepository {

    constructor(
        private readonly _remoteDataSource: TipoPessoaRemoteDataSource,
    ) { }

    getAllTipoPessoa(): Observable<Either<Failure, TipoPessoaEntity[]>> {
        return this._remoteDataSource.getAllTipoPessoa().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }
}