import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { Failure } from '@infra/contracts/failure.contract';
import { TipoEtniaRemoteDataSource } from "@data/data-source/tipo-etnia/tipo-etnia-remote.datasource";
import { TipoEtniaRepository } from "@domain/repositories/tipo-etnia/tipo-etnia.repository";
import { TipoEtniaEntity } from "@domain/entities/tipo-etnia/tipo-etnia.entity";

@Injectable()
export class TipoEtniaRepositoryImpl implements TipoEtniaRepository {

    constructor(
        private readonly _remoteDataSource: TipoEtniaRemoteDataSource,
    ) { }

    getAllTipoEtnia(): Observable<Either<Failure, TipoEtniaEntity[]>> {
        return this._remoteDataSource.getAllTipoEtnia().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }
}