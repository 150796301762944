import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FieldWithErrorFailure, ServerFailure, UnauthorizedFailure } from '@infra/failure/failure';
import { SessionAuthed } from '@shared/utils/session-authed';
import { Helps } from '@main/shared/functions/helps';

@Injectable()
export class HttpServiceInterceptor implements HttpInterceptor {
    constructor(
        private readonly _session: SessionAuthed,
        private router: Router
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = this._session.getTokenApi();
        if(token && !this._session.validationSession()) {
            this._session.clear();
            this.router.navigateByUrl('/login', { replaceUrl: true });
            return throwError(new UnauthorizedFailure());
        }


        if(request.method !== 'GET') request = this.modifyBody(request);        
        if(token) request = this.addToken(request, token);

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if(error?.status === 401) {
                    this._session.clear();
                    return throwError(new UnauthorizedFailure());
                }
                
                if(error?.status === 422)
                    return throwError(new FieldWithErrorFailure(error?.error?.message));

                return throwError(new ServerFailure(error?.error?.message || null));
            })
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private modifyBody(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            body: request.body && !(request.body instanceof FormData) ? {
                ...Helps.removerUndefinedAndEmptyObjects(request.body)
            } : request.body
        });
    }
}