import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';

import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Injectable()
export class SpinnerService {
    private _requestCount = 0;

    constructor(
        private readonly _spinner: NgxSpinnerService,
        private readonly _fuseProgressBarService: FuseProgressBarService
    ) { }

    show(): void {
        this._requestCount++;
        if (this._requestCount > 0 && !this._fuseProgressBarService.isVisible)
            this._spinner.show();
    }

    hide(): void {
        this._requestCount--;
        if (this._requestCount < 0) this._requestCount = 0;
        if (this._requestCount === 0) this._spinner.hide();
    }
}
