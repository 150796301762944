import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { AuthRepository } from "@domain/repositories/auth/auth.repository";
import { Usecase } from '@data/contracts/usecase.contract';
import { AuthEntity } from '@domain/entities/auth/auth.entity';
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from '@infra/success/success';

@Injectable()
export class LogoutAuthUseCase implements Usecase<AuthEntity, SuccessVoid> {

    constructor(
        private readonly _repository: AuthRepository
    ) { }

    execute(param: AuthEntity): Observable<SuccessVoid | Failure> {
        return this._repository.logout().pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<SuccessVoid>) => res.right)
        );
    }
}