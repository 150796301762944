import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { ArquivoRepository } from '@domain/repositories/arquivo/arquivo.repository';
import { ArquivoFonteEnum } from '@shared/enum/arquivo-fonte.enum';

@Injectable()
export class GetSelfArquivoByIdPublicAndFonteUseCase implements Usecase<string, Blob> {

    constructor(
        private readonly _repository: ArquivoRepository
    ) { }

    execute(idPublic: string, fonte: ArquivoFonteEnum): Observable<Blob | Failure> {
        return this._repository.getSelfArquivoByIdPublicAndFonte(idPublic, fonte).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<Blob>) => res.right)
        );
    }
}