import { Observable } from 'rxjs';
import { Either } from 'fp-ts/Either';

import { InscricaoEntity } from '@domain/entities/inscricao/inscricao.entity';
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from '@infra/success/success';


export abstract class InscricaoRepository {
    abstract deleteInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>>;
    abstract restoreInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>>;
    abstract getInscricaoUltimaEtapaByIdPublic(idPublic: string): Observable<Either<Failure, InscricaoEntity>>;
    abstract updateInscricaoLogByIdPublic(param: InscricaoEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>>;
}