import { PermissaoEnum } from '@shared/enum/permissao.enum';
import { FuseNavigation } from "@fuse/types";

export const MENU : FuseNavigation[] = [
    {
        id: 'DEFAULT',
        title: 'Perfil',
        type: 'item',
        icon: 'chevron_right',
        url: '/area-usuario'
    },
    {
        id: PermissaoEnum.VISUALIZAR_EDITAL,
        title: 'Editais',
        type: 'item',
        icon: 'chevron_right',
        url: '/inscricoes'
    },
    {
        id: PermissaoEnum.VISUALIZAR_AVALIACAO_INSCRICAO,
        title: 'Análise de Inscrições',
        type: 'item',
        icon: 'chevron_right',
        url: '/avaliacoes'
    },
    {
        id: PermissaoEnum.VISUALIZAR_AVALIACAO_PRODUTORA,
        title: 'Análise de Produtora',
        type: 'item',
        icon: 'chevron_right',
        url: '/avaliacoes-produtora'
    },
    {
        id: PermissaoEnum.VISUALIZAR_DOCUMENTACAO_PRODUTORA,
        title: 'Documentação',
        type: 'item',
        icon: 'chevron_right',
        url: '/documentacao'
    },
    {
        id: PermissaoEnum.VISUALIZAR_RECURSO,
        title: 'Recurso',
        type: 'item',
        icon: 'chevron_right',
        url: '/recurso-usuario'
    },
    {
        id: PermissaoEnum.VISUALIZAR_AVALIACAO_RECURSO,
        title: 'Análise de Recurso',
        type: 'item',
        icon: 'chevron_right',
        url: '/avaliacoes-recurso'
    },
    {
        id: PermissaoEnum.VISUALIZAR_MEU_RESULTADO,
        title: 'Meus resultados',
        type: 'item',
        icon: 'chevron_right',
        url: '/meu-resultado'
    },
    {
        id: PermissaoEnum.VISUALIZAR_RELATORIO,
        title: 'Relatórios',
        type: 'collapsable',
        icon: 'chevron_right',
        children: [
            {
                id: PermissaoEnum.VISUALIZAR_RELATORIO_EDITAL,
                title: 'Editais',
                type: 'item',
                url: 'relatorio/editais'
            }
        ]
    },
    {
        id: PermissaoEnum.VISUALIZAR_CONFIGURACAO,
        title: 'Configurações',
        type: 'collapsable',
        icon: 'chevron_right',
        children: [
            {
                id: PermissaoEnum.VISUALIZAR_CONFIGURACAO_EDITAL,
                title: 'Editais',
                type: 'item',
                url: 'configuracao/edital'
            },
            {
                id: PermissaoEnum.VISUALIZAR_CONFIGURACAO_CATEGORIA,
                title: 'Categorias',
                type: 'item',
                url: 'configuracao/categoria'
            }
        ]
    },
    {
        id: PermissaoEnum.VISUALIZAR_ADMINISTRACAO,
        title: 'Administração',
        type: 'collapsable',
        icon: 'chevron_right',
        children: [
            {
                id: PermissaoEnum.LISTAR_USUARIO_EXTERNO,
                title: 'Artista e Produtora',
                type: 'item',
                url: 'administracao/usuario/externo'
            },
            {
                id: PermissaoEnum.LISTAR_USUARIO_INTERNO,
                title: 'Usuário interno',
                type: 'item',
                url: 'administracao/usuario/interno'
            }
        ]
    }
]