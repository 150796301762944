import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { BancoRepository } from '@domain/repositories/banco/banco.repository';
import { BancoEntity } from '@domain/entities/banco/banco.entity';

@Injectable()
export class GetAllBancoUseCase implements Usecase<void, BancoEntity[]> {

    constructor(
        private readonly _repository: BancoRepository
    ) { }

    execute(): Observable<BancoEntity[] | Failure> {
        return this._repository.getAllBanco().pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<BancoEntity[]>) => res.right)
        );
    }
}