import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginAuthUseCase } from './usecases/auth/login-auth.usecase';
import { LogoutAuthUseCase } from './usecases/auth/logout-auth.usecase';
import { ForgotPasswordUseCase } from './usecases/auth/forgot-password.usecase';
import { ResetPasswordUseCase } from './usecases/auth/reset-password.usecase';
import { ChangePasswordUseCase } from './usecases/auth/change-password.usecase';

import { GetAllUserPaginatedUseCase } from './usecases/user/get-all-user-paginated.usecase';
import { GetUserAuthUseCase } from '@domain/usecases/user/get-user-auth.usecase';
import { UpdateUserByIdPublicUseCase } from './usecases/user/update-user-by-idpublic.usecase';
import { UpdateStatusUserByIdPublicUseCase } from './usecases/user/update-status-user-by-idpublic.usecase';
import { DeleteUserByIdPublicUseCase } from './usecases/user/delete-user-by-idpublic.usecase';
import { RestoreUserByIdPublicUseCase } from './usecases/user/restore-user-by-idpublic.usecase';
import { GetUserByIdPublicUseCase } from './usecases/user/get-user-by-idpublic.usecase';
import { SaveUserUseCase } from './usecases/user/save-user.usecase';
import { UpdateSelfUserUseCase } from './usecases/user/update-self-user.usecase';
import { GetMyUserUseCase } from './usecases/user/get-my-user.usecase';
import { GetUserProdutoraByIdPublicUseCase } from './usecases/user/get-user-produtora-by-idpublic.usecase';

import { DeleteInscricaoByIdPublicUseCase } from './usecases/inscricao/delete-inscricao-by-idpublic.usecase';
import { RestoreInscricaoByIdPublicUseCase } from './usecases/inscricao/restore-inscricao-by-idpublic.usecase';
import { GetInscricaoUltimaEtapaByIdPublicUseCase } from './usecases/inscricao/get-inscricao-ultima-etapa-by-idpublic.usecase';
import { UpdateInscricaoLogByIdPublicUseCase } from './usecases/inscricao/update-inscricao-log-by-idpublic.usecase';

import { GetAllTipoEtniaUseCase } from './usecases/tipo-etnia/get-all-tipo-etnia.usecase';
import { GetAllTipoPessoaUseCase } from './usecases/tipo-pessoa/get-all-tipo-pessoa.usecase';
import { GetAllMunicipioUseCase } from './usecases/municipio/get-all-municipio.usecase';

import { GetAllDocumentacaoByPerfilAndTipoPessoaUseCase } from './usecases/documentacao/get-all-documentacao-by-perfil-and-tipo-pessoa.usecase';
import { GetArquivoByIdPublicAndFonteUseCase } from './usecases/arquivo/get-arquivo-by-id-public-and-fonte.usecase';
import { GetSelfArquivoByIdPublicAndFonteUseCase } from './usecases/arquivo/get-self-arquivo-by-id-public-and-fonte.usecase';

import { GetAllPerfilStatusAtivoUseCase } from './usecases/perfil/get-all-perfil-status-ativo.usecase';

import { GetAllBancoUseCase } from './usecases/banco/get-all-banco.usecase';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        // Auth
        LoginAuthUseCase,
        LogoutAuthUseCase,
        ForgotPasswordUseCase,
        ResetPasswordUseCase,
        ChangePasswordUseCase,

        // User
        GetAllUserPaginatedUseCase,
        GetUserAuthUseCase,
        UpdateStatusUserByIdPublicUseCase,
        UpdateUserByIdPublicUseCase,
        DeleteUserByIdPublicUseCase,
        RestoreUserByIdPublicUseCase,
        GetUserByIdPublicUseCase,
        SaveUserUseCase,
        UpdateSelfUserUseCase,
        GetMyUserUseCase,
        GetUserProdutoraByIdPublicUseCase,

        // Inscrição
        DeleteInscricaoByIdPublicUseCase,
        RestoreInscricaoByIdPublicUseCase,
        GetInscricaoUltimaEtapaByIdPublicUseCase,
        UpdateInscricaoLogByIdPublicUseCase,

        // Tipo de Etnia
        GetAllTipoEtniaUseCase,

        // Tipo de Pessoa
        GetAllTipoPessoaUseCase,

        // Municipios
        GetAllMunicipioUseCase,
        
        // Documentação
        GetAllDocumentacaoByPerfilAndTipoPessoaUseCase,

        // Arquivo Usuário
        GetArquivoByIdPublicAndFonteUseCase,
        GetSelfArquivoByIdPublicAndFonteUseCase,

        // Perfil
        GetAllPerfilStatusAtivoUseCase,

        // Banco
        GetAllBancoUseCase,
    ]
})
export class DomainModule { }
