import { Failure } from "../contracts/failure.contract";
import { Message } from './../constants/message/message';

export class ServerFailure extends Failure {    
    constructor(message?: string) {
        super(message ?? Message.requestFailure);
    }
}

export class NoDataFoundFailure extends Failure { }

export class FieldWithErrorFailure extends Failure {
    constructor(message?: string) {
        super(message ?? Message.fieldWithErrorFailure);
    }
}

export class NoAuthenticationFailure extends Failure {
    constructor(message?: string) {
        super(message ?? Message.noAuthentication);
    }
}

export class UnauthorizedFailure extends Failure {
    constructor(message?: string) {
        super(message ?? Message.unauthorized);
    }
}

export class UserOrPasswordFailure extends Failure {
    constructor(message?: string) {
        super(message ?? Message.unauthorized);
    }
}